import {
  CHANGE_CURRENT_LOGGER,
  SET_LOGGERS,
  SET_UPDATE_LOGGERS,
  DEFAULT_ACTION,
  ADD_NOTIFICATION,
  DELETE_NOTIFICATION
} from 'actions/globalActions';

const findLoggerId = () => {
  let fallback = null;
  const localStorage = window.localStorage.getItem('last-logger');
  
  if (localStorage && localStorage !== 'null')
    return localStorage;

  const params = window.location.pathname.split('/');
  if (params.length >= 1) {
    fallback = params[1];
  }

  return fallback;
};

const initialState = {
  currentLogger: findLoggerId(),
  setUpdateLoggers: true,
  loggers: [],
  notifications: [],
};

let numErrors = 0;

const globalReducer = (state = initialState, action = DEFAULT_ACTION) => {
  switch (action.type) {
    case CHANGE_CURRENT_LOGGER:
      window.localStorage.setItem('last-logger', action.logger);
      return {
        ...state,
        currentLogger: action.logger
      };
    case SET_LOGGERS:
      return {
        ...state,
        loggers: action.loggers
      };
    case SET_UPDATE_LOGGERS:
      return {
        ...state,
        setUpdateLoggers: action.value
      };
    case ADD_NOTIFICATION:
      return {
        ...state,
        notifications: [
          ...state.notifications,
          {
            id: ++numErrors,
            ...action.data
          }
        ]
      };
    case DELETE_NOTIFICATION:
      return {
        ...state,
        notifications: [
          ...state.notifications.slice(0, state.notifications.findIndex(e => e.id === action.data)),
          ...state.notifications.slice(state.notifications.findIndex(e => e.id === action.data) + 1)
        ]
      };
    default:
      return state;
  }
};


export default globalReducer;
