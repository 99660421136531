import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

import './styles.scss';

const MethodLabel = ({ method = 'GET' }) => {
  return (
    <span
      className={cx('tag', 'is-rounded', 'is-uppercase', 'method-label', method.toLowerCase())}
    >
      {method}
    </span>
  );
};

MethodLabel.propTypes = {
  method: PropTypes.string,
};

export default MethodLabel;
