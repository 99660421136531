import React, { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import Layout from 'components/Layout/Layout';
import Table from 'components/common/Table';
import api from 'services/api';

const Whitelist = () => {
  const currentLogger = useSelector(state => state.global.currentLogger);

  const [data, setData] = useState({});
  const [isLoading, setIsLoading] = useState(true);
  const [isError, setIsError] = useState(false);

  const [curPage, setCurPage] = useState(1);
  const [nRequests] = useState(20);

  const formatTimestamp = (timestamp) => {
    if (!timestamp) {
      return;
    }

    return new Date(timestamp).toLocaleString();
  };

  const parseData = (data) => {
    if (!data.whiteListers) {
      return;
    }
    const whiteListers = data.whiteListers;
    const dataParsed = [];
    for (let i = 0; i < whiteListers.length; i++) {
      dataParsed.push([
        i, { ipAddress: whiteListers[i].ipAddress, loggerId: currentLogger, requesterId: whiteListers[i].id }, whiteListers[i].avgTimeTaken, whiteListers[i].totalRequests,
        formatTimestamp(whiteListers[i].firstAccess), formatTimestamp(whiteListers[i].lastAccess), { name: whiteListers[i].name, color: whiteListers[i].colorLabel }
      ]);
    }
    return dataParsed;
  };

  /**
   * Fetches for log data.
   */
  const fetchWhitelist = useCallback(() => {
    setIsLoading(true);
    setIsError(false);
    api.getWhitelist(currentLogger, nRequests, curPage, res => {
      setIsLoading(false);
      if (res.data.status === 200) {
        setData(res.data);
      } else {
        setIsError(true);
      }
    });
  }, [curPage, currentLogger, nRequests]);

  /**
   * Calls fetchLogs onload.
   */
  useEffect(() => {
    if (isLoading) {
      fetchWhitelist();
    }
  }, [isLoading, fetchWhitelist]);

  return (
    <Layout title={'Whitelist'} loading={isLoading} error={isError}>
      {isError}
      <Table
        headerAttributes={[
          'IP Address', 'Avg Time per Request (ms)', 'Total Requests',
          'First Access', 'Last Access', 'Label'
        ]}
        data={parseData(data)}
        drawIpAddressPosition={1}
        drawGroupPosition={6}
        curPage={curPage}
        nPages={data.numPages}
        callBackUrl={setCurPage}
        setLoading={setIsLoading}
      />
    </Layout>
  );
};

export default Whitelist;
