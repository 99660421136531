import React, { useState, useEffect, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import api from 'services/api';

import Layout from 'components/Layout/Layout';
import Button from 'components/common/Button';
import Drawer from 'components/common/Drawer';
import NewStopwordForm from 'components/Stopwords/NewStopwordForm';
import StopwordsTable from 'components/Stopwords/StopwordsTable';
import { addNotification } from 'actions/globalActions';

const Stopwords = () => {
  const currentLogger = useSelector(state => state.global.currentLogger);
  const dispatch = useDispatch();

  const [isLoading, setIsLoading] = useState(true);
  const [isError, setIsError] = useState(false);
  const [stopwords, setStopwords] = useState([]);
  const [labels, setLabels] = useState([]);
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [isDrawerLoading, setIsDrawerLoading] = useState(false);
  const [isDrawerError, setIsDrawerError] = useState(false);


  const createStopwords = (data) => {
    setIsDrawerLoading(true);
    console.log('Create stopword data', data);
    api.createStopword(currentLogger, data, res => {
      if (res.data.status === 200) {
        setStopwords([
          ...stopwords,
          res.data.stopword,
        ]);
        dispatch(addNotification({
          title: 'Stopword created successfully',
          type: 'success',
        }));
        setIsDrawerOpen(false);
      } else {
        setIsDrawerError(true);
      }
      setIsDrawerLoading(false);
    });
  };

  const fetchStopwords = useCallback(() => {
    setIsLoading(true);
    setIsError(false);
    api.getStopwords(currentLogger, res => {
      if (res.data.status === 200) {
        setStopwords(res.data.stopwords);
        setLabels(res.data.labels);
      } else {
        setIsError(true);
      }
      setIsLoading(false);
    });
  }, [currentLogger]);

  const deletethis = null;
  if (deletethis !== null) {
    dispatch();
    setLabels();
    setIsDrawerError();
    setIsDrawerLoading();
    currentLogger;
  }

  /**
   * Calls fetchLogs onload.
   */

  useEffect(() => {
    fetchStopwords();
  }, [fetchStopwords]);


  return (
    <Layout title={'Stopwords'} loading={isLoading} error={isError} className="is-relative">
      <Drawer isError={isDrawerError} isLoading={isDrawerLoading} isOpen={isDrawerOpen} onClose={() => setIsDrawerOpen(false)}>
        <NewStopwordForm onSubmitForm={createStopwords} labels={labels} />
      </Drawer>
      <div className="columns">
        <div className="column">
          <h1 className="title mb-1">Stopwords</h1>
          <p className="has-text-weight-light">
            You can create matching rules for stopwords
          </p>
        </div>
        <div className="column is-narrow">
          <Button onClick={() => setIsDrawerOpen(true)}>
            New Stopword
          </Button>
        </div>
      </div>
      <StopwordsTable stopwords={stopwords} labels={labels} setStopwords={setStopwords} />
    </Layout>
  );
};

export default Stopwords;