import React from 'react';
import cx from 'classnames';
import PropTypes from 'prop-types';

import './styles.scss';

const Card = ({ title, noPadding = false, className, children }) => {

  const getHeader = () => {
    if (!title) {
      return;
    }

    return (
      <header className="card-header">
        <div className="card-header-title">
          {title}
        </div>
      </header>
    );
  };

  return (
    <div className={cx('card', className)}>
      {getHeader()}
      <div className={cx('card-content', {
        'px-0 pb-0 pt-1': noPadding
      })}
      >
        {children}
      </div>
    </div>
  );
};

Card.propTypes = {
  title: PropTypes.any,
  className: PropTypes.string,
  noPadding: PropTypes.bool,
  children: PropTypes.any
};

export default Card;
