import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Card from 'components/common/Card';
import { truncateIntegerNumber } from 'services/valueFormatter';
import Button from 'components/common/Button';
import Icon from 'components/common/Icon';

const CountryTableCard = ({ countries }) => {

  const [page, setPage] = useState(0); // page 0 or 1
  const perPage = 10;

  const drawCountryRows = () => (
    countries.map(({ name, count }, index) => {
      if (index >= page * perPage && index < (page + 1) * perPage) {
        return (
          <tr
            key={name}
          >
            <td>
              <p className="is-size-7 has-tooltip-top" data-tooltip={name}>
                {name}
              </p>
              <p className="is-size-7">
                <span className="has-text-weight-bold">
                  {truncateIntegerNumber(count)}
                </span>
                &nbsp;requests
              </p>
            </td>
          </tr>
        );
      }
    })
  );

  const drawPagination = () => {
    if (countries.length !== 0) {
      return (
        <div className="is-flex is-justify-content-center">
          <Button
            type="text"
            size="small"
            className="mr-2"
            onClick={() => setPage(0)}
          >
            <Icon
              color={page === 0 ? 'grey' : 'primary'}
              icon="fa-chevron-left"
            />
          </Button>
          <Button
            type="text"
            size="small"
            className="ml-2"
            onClick={() => setPage(1)}
          >
            <Icon
              color={page === 1 ? 'grey' : 'primary'}
              icon="fa-chevron-right"
            />
          </Button>
        </div>
      );
    }
  };

  return (
    <Card>
      <h3 className="has-text-weight-bold has-text-light mb-2">
        Top 20 Countries
      </h3>
      <div style={{ height: 500 }}>
        <table className="table is-fullwidth is-narrow">
          <tbody>
            {drawCountryRows()}
          </tbody>
        </table>
        {drawPagination()}
      </div>
    </Card>
  );

};

CountryTableCard.propTypes = {
  countries: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      count: PropTypes.string
    })
  ),
};

export default CountryTableCard;
