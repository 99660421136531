import React from 'react';
import PropTypes from 'prop-types';
import { scaleLinear } from 'd3-scale';
import { ComposableMap, Geographies, Geography } from 'react-simple-maps';

const geoUrl =
  'https://cdn.jsdelivr.net/npm/world-atlas@2/countries-110m.json';

const MapChart = ({ setTooltipContent, countries, width, height }) => {
  const colorScale = scaleLinear()
    .domain([
      0,
      Math.max.apply(
        Math,
        countries !== undefined ? countries.map((country) => {
          return country.count;
        }) : []
      ),
    ])
    .range(['#e7c6c5', '#b0413e']);

  return (
    <ComposableMap
      data-tip=""
      projectionConfig={{
        scale: 120,
        rotation: [-11, 0, 0],
      }}
      width={width}
      height={height}
    >

      <Geographies geography={geoUrl}>
        {({ geographies }) =>
          geographies.map((geo) => {
            const country = countries.find(
              (s) => s.id === geo.id
            );
            return (
              <Geography
                key={geo.rsmKey}
                geography={geo}
                fill={(country) ? colorScale(country.count) : '#dedcdc'}
                onMouseEnter={() => {
                  country !== undefined && country !== null
                    ? setTooltipContent(
                      `${country.name} — ${country.count} requests`
                    )
                    : setTooltipContent(
                      `${geo.properties.name} — 0 requests`
                    );
                }}
                onMouseLeave={() => {
                  setTooltipContent('');
                }}
                style={{
                  hover: {
                    fill: '#22333B',
                    outline: 'none',
                  }
                }}
              />
            );
          })
        }
      </Geographies>
    </ComposableMap>
  );
};

MapChart.propTypes = {
  countries: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      count: PropTypes.string
    })
  ),
  setTooltipContent: PropTypes.func,
  width: PropTypes.number,
  height: PropTypes.number,
};

export default MapChart;
