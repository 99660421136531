import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

import './styles.scss';

// const colors = ['#264653', '#2a9d8f', '#e9c46a', '#f4a261', '#e76f51', '#e63946', '#b5e48c', '#d4a373', '#e9edc9', '#06d6a0', '#e07a5f'];
const colors = [
  '#f94144', '#590d22', '#ff4d6d', '#ff8fa3', // red/pink
  '#f3722c', '#f8961e', '#f9844a', '#f9c74f', // orange/yellow
  '#90be6d', '#43aa8b', '#4d908e', '#06d6a0', // green/blue
  '#577590', '#277da1', '#3a86ff', '#c0fdff', //blue
  '#8338ec', '#c77dff' // purple
];

const ColorPicker = ({ color, setColor }) => {

  const getColors = () => (
    colors.map((colorElement) => (
      <div
        key={colorElement}
        className="column is-2 is-flex is-justify-content-center"
      >
        <div
          className={cx('color-button', {
            'selected': color === colorElement
          })}
          onClick={() => setColor(colorElement)}
        >
          <span
            className="color-button-bubble"
            style={{
              backgroundColor: colorElement,
            }}
          />
        </div>
      </div>
    ))
  );

  return (
    <div className="color-picker columns is-mobile is-multiline is-variable is-1">
      {getColors()}
    </div>
  );
};

ColorPicker.propTypes = {
  color: PropTypes.string,
  setColor: PropTypes.func,
};

export default ColorPicker;
