import React, { useCallback, useEffect } from 'react';
import cx from 'classnames';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';

import { deleteNotification } from 'actions/globalActions';

const Toast = ({ type = 'info', isLight, timeout = 5000, id, title, description }) => {

  const dispatch = useDispatch();

  const deleteToast = useCallback(() => {
    dispatch(deleteNotification(id));
  }, [dispatch, id]);

  const getIconClass = (type) => {
    switch (type) {
      case 'success':
        return 'fa-check-circle';
      case 'danger':
        return 'fa-times-circle';
      case 'info':
        return 'fa-info-circle';
      case 'warning':
        return 'fa-exclamation-circle';
      default:
    }
  };

  /**
   * We want the toast to be deleted after some time
   */
  useEffect(() => {
    setTimeout(() => {
      deleteToast();
    }, timeout);
  }, [deleteToast, timeout]);

  return (
    <div
      className={cx('notification toast', type ? `is-${type}` : '', {
        'is-light': isLight,
      })}
    >
      <button className="delete" onClick={deleteToast}></button>
      <div className="is-flex is-align-items-center mb-1">
        <span className="icon">
          <i className={`fas ${getIconClass(type)}`}></i>
        </span>
        <h3 className="title is-5">
          {title}
        </h3>
      </div>
      <p>
        {description}
      </p>
    </div>
  );
};

Toast.propTypes = {
  type: PropTypes.oneOf(['success', 'danger', 'info', 'warning']),
  isLight: PropTypes.bool,
  timeout: PropTypes.number,
  id: PropTypes.any,
  title: PropTypes.string,
  description: PropTypes.string,
};

export default Toast;
