import React, { useCallback, useState } from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router';
import PropTypes from 'prop-types';

import { formatDate } from 'services/valueFormatter';
import MethodLabel from 'components/common/MethodLabel';
import Paginator from 'components/common/Paginator';
import Card from 'components/common/Card';
import api from 'services/api';

const RequestsTable = ({ requests, numPages, tableTitle='Requests' }) => {

  const { requesterId: currentRequester } = useParams();
  const currentLogger = useSelector(state => state.global.currentLogger);

  const perPage = 10;

  const [currentRequests, setCurrentRequests] = useState(requests);
  const [currentNumPages, setCurrentNumPages] = useState(numPages);
  const [currentPage, setCurrentPage] = useState(1);
  const [isLoading, setIsLoading] = useState(true);
  const [isError, setIsError] = useState(false);

  const getRequesterRequests = useCallback((page) => {
    setIsLoading(true);
    setIsError(false);
    api.getRequesterRequests(currentLogger, currentRequester, perPage, page, res => {
      if (res.data.status === 200) {
        setCurrentRequests(res.data.requests);
        setCurrentNumPages(res.data.numPages);
        setCurrentPage(page);
      } else {
        setIsError(true);
      }
      setIsLoading(false);
    });
  }, [currentLogger, currentRequester]);

  const getRequestRows = () => (
    currentRequests.map(({ id, method, uri, timeTaken, numberQueries, createdAt }) => (
      <tr
        key={id}
      >
        <td>
          <MethodLabel method={method} />
        </td>
        <td>
          <a className="has-text-link is-size-7 has-tooltip-top" href={uri} data-tooltip={uri} target="_blank" rel="noreferrer">
            {uri}
          </a>
        </td>
        <td className="is-size-7">
          {timeTaken}
        </td>
        <td className="is-size-7">
          {numberQueries}
        </td>
        <td className="is-size-7">
          {formatDate(createdAt)}
        </td>
      </tr>
    ))
  );

  return (
    <Card>
      <h3 className="has-text-weight-bold has-text-light">
        {tableTitle}
      </h3>
      {isLoading}
      {isError}
      <table className="table is-fullwidth is-narrow is-striped">
        <thead>
          <tr>
            <th>Method</th>
            <th>URI</th>
            <th>Time Taken</th>
            <th>Queries</th>
            <th>Timestamp</th>
          </tr>
        </thead>
        <tbody>
          {getRequestRows()}
        </tbody>
        <tfoot>
          <tr>
            <td colSpan={5}>
              <Paginator
                currentPage={currentPage}
                pagesNum={currentNumPages}
                callBackUrl={getRequesterRequests}
                setLoading={setIsLoading}
              />
            </td>
          </tr>
        </tfoot>
      </table>
    </Card>
  );
};

RequestsTable.propTypes = {
  requests: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      method: PropTypes.string,
      uri: PropTypes.string,
      timeTaken: PropTypes.any,
      numberQueries: PropTypes.any,
      createdAt: PropTypes.string
    })
  ),
  numPages: PropTypes.number,
  tableTitle: PropTypes.string
};

export default RequestsTable;
