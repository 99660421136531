import React, { Fragment, useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { setLoggers, setUpdateLoggers } from 'actions/globalActions';
import api from 'services/api';

const DataHandler = () => {
  const dispatch = useDispatch();

  const shouldUpdate = useSelector((state) => state.global.setUpdateLoggers);

  const fetchLoggers = useCallback(() => {
    api.getLoggers(
      (res) => {
        if (res.data.status === 200) {
          dispatch(setLoggers(res.data.loggers));
          dispatch(setUpdateLoggers(false));
        }
      },
      (error) => {
        console.log(`Create logger error: ${error}`);
        dispatch(setUpdateLoggers(false));
      }
    );
  }, [dispatch]);

  useEffect(() => {
    if (shouldUpdate === true) {
      fetchLoggers();
    }
  }, [fetchLoggers, shouldUpdate]);

  return <Fragment></Fragment>;
};

export default DataHandler;
