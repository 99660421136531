/**
 * Using locale as undefined makes the browser use the user's locale
 */
const locale = undefined;

/**
 * Date format options
 */
const options = {
  year: 'numeric',
  month: 'long',
  day: 'numeric',
  hour: 'numeric',
  minute: 'numeric',
};

/**
 * Number of decimal places
 */
const decimalOptions = {
  minimumFractionDigits: 1,
  maximumFractionDigits: 2
};

const formatDate = (dateString) =>
  new Date(dateString).toLocaleDateString(locale, options);

const truncateIntegerNumber = (val) => {

  if (!val) {
    return '0';
  }

  return val.toLocaleString(locale);

  // return Math.abs(Number(val)) >= 1.0e9
  //   ? Math.abs(Number(val)) / 1.0e9 + 'B'
  //   : Math.abs(Number(val)) >= 1.0e6
  //     ? Math.abs(Number(val)) / 1.0e6 + 'M'
  //     : Math.abs(Number(val)) >= 1.0e3
  //       ? Math.abs(Number(val)) / 1.0e3 + 'K'
  //       : Math.abs(Number(val));
};

const truncateDecimalNumber = (val) => {
  if (!val) {
    return '0.00';
  }

  return val.toLocaleString(locale, decimalOptions);


  // return Math.abs(Number(val)) >= 1.0e9
  //   ? Math.abs(Number(val)) / 1.0e9 + 'B'
  //   : Math.abs(Number(val)) >= 1.0e6
  //     ? Math.abs(Number(val)) / 1.0e6 + 'M'
  //     : Math.abs(Number(val)) >= 1.0e3
  //       ? Math.abs(Number(val)) / 1.0e3 + 'K'
  //       : Math.abs(Number(val));
};

const formatDecimalNumber = (val, decimalPlaces = 1) => {
  if (!val) {
    const zero = 0;
    return zero.toFixed(decimalPlaces);
  }

  return val.toFixed(decimalPlaces);
};

const truncateTime = (val) => {
  return Math.abs(Number(val)) >= 6.0e4
    ? (Math.abs(Number(val)) / 6.0e4).toLocaleString(locale, decimalOptions) + 'min'
    : Math.abs(Number(val)) >= 1.0e3
      ? (Math.abs(Number(val)) / 1.0e3).toLocaleString(locale, decimalOptions) + 's'
      : Math.abs(Number(val)).toLocaleString(locale, decimalOptions) + 'ms';
};

export {
  formatDate,
  truncateTime,
  truncateDecimalNumber,
  truncateIntegerNumber,
  formatDecimalNumber
};
