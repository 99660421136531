import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { formatDate } from 'services/valueFormatter';

import Card from 'components/common/Card';
import Button from 'components/common/Button';
import Icon from 'components/common/Icon';
import Stamp from 'components/common/Stamp';
import { useDispatch, useSelector } from 'react-redux';
import Drawer from 'components/common/Drawer';
import api from 'services/api';
import { addNotification } from 'actions/globalActions';
import EditKeyForm from '../EditKeyForm';


const KeyCard = ({
  id,
  name,
  hashKey,
  createdAt,
  renameKeyCallback,
  deleteKeyCallback
}) => {

  const currentLogger = useSelector(state => state.global.currentLogger);
  const dispatch = useDispatch();

  // edit drawer
  const [isEditDrawerOpen, setIsEditDrawerOpen] = useState(false);
  const [isEditDrawerLoading, setIsEditDrawerLoading] = useState(false);
  const [isEditDrawerError, setIsEditDrawerError] = useState(false);

  // delete drawer
  const [isDeleteDrawerOpen, setIsDeleteDrawerOpen] = useState(false);
  const [isDeleteDrawerLoading, setIsDeleteDrawerLoading] = useState(false);
  const [isDeleteDrawerError, setIsDeleteDrawerError] = useState(false);

  const copyToClipboard = (content) => {
    const el = document.createElement('textarea');
    el.value = content;
    el.setAttribute('readonly', '');
    el.style.position = 'absolute';
    el.style.left = '-9999px';
    document.body.appendChild(el);
    el.select();
    el.setSelectionRange(0, 99999);
    document.execCommand('copy');
    document.body.removeChild(el);
  };

  const editKey = (data) => {
    setIsEditDrawerLoading(true);
    api.renameKey(currentLogger, id, data, res => {
      const { status, key } = res.data;
      if (status === 200) {
        renameKeyCallback(key);
        dispatch(addNotification({
          title: 'Key edited successfully',
          type: 'success',
        }));
        setIsEditDrawerOpen(false);
      } else {
        setIsEditDrawerError(true);
      }
      setIsEditDrawerLoading(false);
    });
  };

  const deleteKey = () => {
    setIsDeleteDrawerLoading(true);
    api.deleteKey(currentLogger, id, res => {
      const { status } = res.data;
      if (status === 200) {
        dispatch(addNotification({
          title: 'Key deleted successfully',
          type: 'success',
        }));
        setIsDeleteDrawerOpen(false);
        setIsDeleteDrawerLoading(false);
        deleteKeyCallback(id);
      } else {
        setIsDeleteDrawerError(true);
        setIsDeleteDrawerLoading(false);
      }
    });
  };

  return (
    <>
      <Card>
        <Stamp
          className="mb-2"
          size="6"
          title="Name"
          value={name}
        />
        <Stamp
          className="mb-2"
          size="6"
          title="Created At"
          value={formatDate(createdAt)}
        />
        {/* Special Stamp with code formatting */}
        <div className="is-block mb-2">
          <h3 className="has-text-weight-bold has-text-light is-inline">
            Key Hash
          </h3>
          <Button
            tooltipText="Copy to Clipboard"
            className="is-pulled-right has-tooltip-left"
            type="text"
            noPadding
            onClick={() => copyToClipboard(hashKey)}
          >
            <Icon
              size="medium"
              icon="fa-clipboard"
              color="grey"
            />
          </Button>
          <div className="is-flex is-justify-content-space-between">
            <p className="is-size-6">
              <code>
                {hashKey}
              </code>
            </p>
          </div>
        </div>
        <Button
          type="text"
          onClick={() => setIsEditDrawerOpen(true)}
        >
          Edit
        </Button>
        <Button
          type="text"
          className="is-pulled-right"
          onClick={() => setIsDeleteDrawerOpen(true)}
        >
          Delete
        </Button>
      </Card >
      <Drawer
        isError={isEditDrawerError}
        isLoading={isEditDrawerLoading}
        isOpen={isEditDrawerOpen}
        onClose={() => setIsEditDrawerOpen(false)}

      >
        <EditKeyForm name={name} onSubmitForm={editKey} />
      </Drawer>
      <Drawer
        isError={isDeleteDrawerError}
        isLoading={isDeleteDrawerLoading}
        isOpen={isDeleteDrawerOpen}
        onClose={() => setIsDeleteDrawerOpen(false)}
      >
        <p className="has-text-weight-bold is-size-4 mb-2">Are you sure you want to delete this key?</p>
        <p className="has-text-weight-light">This actions is irreversible and you will never be able to use this key again.</p>
        <Button className="is-pulled-right" type="text" onClick={deleteKey}>
          Delete API Key
        </Button>
      </Drawer>
    </>
  );
};

KeyCard.propTypes = {
  name: PropTypes.string,
  createdAt: PropTypes.string,
  hashKey: PropTypes.string,
  id: PropTypes.string,
  renameKeyCallback: PropTypes.func,
  deleteKeyCallback: PropTypes.func,
};

export default KeyCard;
