import axios from 'axios';

/**
 * Constant variables
 */
const API_URL = process.env.REACT_APP_API_URL ? process.env.REACT_APP_API_URL : 'http://localhost:2002';
const JTW_TOKEN_KEY = 'JWT_TOKEN';

/**
 * All the server API routes.
 */
const routes = {
  // general
  login: '/login',
  logout: '/logout',
  // logger
  createLogger: '/logger',
  getLoggers: '/logger',
  getLogger: (loggerId) => `/logger/${loggerId}`,
  updateLogger: (loggerId) => `/logger/${loggerId}`,
  deleteLogger: (loggerId) => `/logger/${loggerId}`,
  // dashboards
  getActivity: (loggerId, value, timeframe) => (`logger/${loggerId}/activity?value=${value}&timeframe=${timeframe}`),
  getPerformance: (loggerId, value, timeframe) => (`logger/${loggerId}/performance?value=${value}&timeframe=${timeframe}`),
  getLiveSessions: (loggerId) => (`logger/${loggerId}/sessions`),
  // requesters
  getRequesters: (loggerId, perPage, page, query) => (`logger/${loggerId}/requesters?perPage=${perPage}&page=${page}&searchQuery=${query}`),
  getTopRequesters: (loggerId, perPage, page) => (`logger/${loggerId}/requesters/top?perPage=${perPage}&page=${page}`),
  getRequester: (loggerId, requesterId, perPage, page) => (`logger/${loggerId}/requesters/${requesterId}?perPage=${perPage}&page=${page}`),
  getRequesterRequests: (loggerId, requesterId, perPage, page) => (`logger/${loggerId}/requesters/${requesterId}/requests?perPage=${perPage}&page=${page}`),
  updateRequesterLabel: (loggerId, requesterId) => (`logger/${loggerId}/requesters/${requesterId}/label`),
  updateRequesterGroup: (loggerId, requesterId) => (`logger/${loggerId}/requesters/${requesterId}/group`),
  deleteRequesterLabel: (loggerId, requesterId) => (`logger/${loggerId}/requesters/${requesterId}/label`),
  deleteRequesterGroup: (loggerId, requesterId) => (`logger/${loggerId}/requesters/${requesterId}/group`),
  updateRequesterFlags: (loggerId, requesterId) => (`logger/${loggerId}/requesters/${requesterId}/flags`),
  updateRequesterOverride: (loggerId, requesterId) => (`logger/${loggerId}/requesters/${requesterId}/override`),
  // Logs/Requests
  getBots: (loggerId, perPage, page) => (`logger/${loggerId}/bots?perPage=${perPage}&page=${page}`),
  getWhitelist: (loggerId, perPage, page) => (`logger/${loggerId}/whitelist?perPage=${perPage}&page=${page}`),
  getBlacklist: (loggerId, perPage, page) => (`logger/${loggerId}/blacklist?perPage=${perPage}&page=${page}`),
  getLogs: (loggerId, perPage, page) => (`logger/${loggerId}/logs?perPage=${perPage}&page=${page}`),
  getSuspiciousLogs: (loggerId, perPage, page) => (`logger/${loggerId}/suspicious?perPage=${perPage}&page=${page}`),
  // groups
  getGroups: (loggerId) => (`logger/${loggerId}/groups`),
  updateGroup: (loggerId, groupId) => (`logger/${loggerId}/groups/${groupId}`),
  createGroup: (loggerId) => (`logger/${loggerId}/groups`),
  deleteGroup: (loggerId, groupId) => (`logger/${loggerId}/groups/${groupId}`),
  // Crawlers
  getCrawlers: (loggerId) => (`logger/${loggerId}/crawlers`),
  updateCrawlers: (loggerId, crawlerId) => (`logger/${loggerId}/crawlers/${crawlerId}`),
  createCrawler: (loggerId) => (`logger/${loggerId}/crawlers`),
  deleteCrawler: (loggerId, crawlerId) => (`logger/${loggerId}/crawlers/${crawlerId}`),
  // Stopwords
  getStopwords: (loggerId) => (`logger/${loggerId}/stopwords`),
  updateStopwords: (loggerId, stopwordId) => (`logger/${loggerId}/stopwords/${stopwordId}`),
  createStopword: (loggerId) => (`logger/${loggerId}/stopwords`),
  deleteStopword: (loggerId, stopwordId) => (`logger/${loggerId}/stopwords/${stopwordId}`),
  // labels
  getLabels: (loggerId) => (`logger/${loggerId}/labels`),
  updateLabel: (loggerId, labelId) => (`logger/${loggerId}/labels/${labelId}`),
  createLabel: (loggerId) => (`logger/${loggerId}/labels`),
  deleteLabel: (loggerId, labelId) => (`logger/${loggerId}/labels/${labelId}`),
  // keys
  getKeys: (loggerId) => (`logger/${loggerId}/key`),
  createKey: (loggerId) => (`logger/${loggerId}/key`),
  deleteKey: (loggerId, keyId) => (`logger/${loggerId}/key/${keyId}`),
  renameKey: (loggerId, keyId) => (`logger/${loggerId}/key/${keyId}/rename`),
  addToBots: (loggerId) => (`logger/${loggerId}/bots`),
  addToGroup: (loggerId) => (`logger/${loggerId}/requesters`)
};

/**
 * Gets the user session token
 * from localStorage.
 */
const getToken = () => localStorage.getItem(JTW_TOKEN_KEY);

/**
 * Makes an authenticated request
 * to the path specified.
 *
 * In case the user is not authenticated,
 * makes an unauthenticated request.
 */
const request = (path, method, data, _cb, noTrailingSlash) => {
  if (!path.startsWith('/')) {
    path = '/' + path;
  }
  if (!path.endsWith('/') && !noTrailingSlash) {
    path = path + '/';
  }

  const headers = {};

  // Add authorization header
  const token = getToken();
  if (token) {
    headers.Authorization = `Bearer ${token}`;
  }

  const errorHandler = (err) => {
    let errorMsg = '';
    if (err.response) { // Server replied with non 2xx
      errorMsg = `Server replied with non 2xx:\n ${err}`;
    } else if (err.request) { // Network error / Server did not reply
      errorMsg = `Network error / Server did not reply\n ${err}`;
    } else { // Other error
      errorMsg = `Other error\n ${err}`;
    }

    const res = { data: { status: 'error', errorMsg: errorMsg } };
    _cb(res);
  };

  const authMiddleware = (_cb) => {
    return (res) => {
      if (res.data.status === 401) {
        if (window.localStorage.getItem('JWT_TOKEN')) {
          window.localStorage.removeItem('JWT_TOKEN');
          window.location.pathname = '/'; // Hard refresh
        }
      } else {
        _cb(res);
      }
    };
  };


  if (path.startsWith('/')) {
    path = path.substring(1);
  }

  console.log(`Sending to this url: ${API_URL} with this path ${path}`);
  // console.log('Sending this data = ' + JSON.stringify(data));
  if (method.toLowerCase() === 'get') {
    axios.get(API_URL + path, { headers }).then(authMiddleware(_cb)).catch(errorHandler);
  } else if (method.toLowerCase() === 'post') {
    axios.post(API_URL + path, data, { headers }).then(authMiddleware(_cb)).catch(errorHandler);
  } else if (method.toLowerCase() === 'delete') {
    axios.delete(API_URL + path, { headers }).then(authMiddleware(_cb)).catch(errorHandler);
  }
};

/**
 * Main API controller.
 *
 * Deals with all communication between
 * the app and the server.
 */
const api = {
  login: (data, _cb) => {
    console.log('Making request to the api');
    request(routes.login, 'post', data, _cb);
  },
  logout: (_cb) => {
    request(routes.logout, 'post', null, _cb);
  },
  createLogger: (data, _cb) => {
    request(routes.createLogger, 'post', data, _cb);
  },
  getLoggers: (_cb) => {
    request(routes.getLoggers, 'get', null, _cb);
  },
  getLogger: (loggerId, _cb) => {
    request(routes.getLogger(loggerId), 'get', null, _cb);
  },
  updateLogger: (loggerId, data, _cb) => {
    request(routes.updateLogger(loggerId), 'post', data, _cb);
  },
  deleteLogger: (loggerId, _cb) => {
    request(routes.deleteLogger(loggerId), 'delete', null, _cb);
  },
  // activity
  getActivity: (loggerId, value, timeframe, _cb) => {
    console.log('Get activity data logger = ' + loggerId + 'value = ' + value + 'timeframe = ' + timeframe);

    request(routes.getActivity(loggerId, value, timeframe), 'get', null, _cb, true);
  },
  getPerformance: (loggerId, value, timeframe, _cb) => {
    request(routes.getPerformance(loggerId, value, timeframe), 'get', null, _cb, true);
  },
  // Live Sessions
  getliveSessions: (loggerId, _cb) => {
    request(routes.getLiveSessions(loggerId), 'get', null, _cb);
  },
  // requesters
  getRequesters: (loggerId, perPage, page, query, _cb) => {
    request(routes.getRequesters(loggerId, perPage, page, query), 'get', null, _cb);
  },
  getTopRequesters: (loggerId, perPage, page, _cb) => {
    request(routes.getTopRequesters(loggerId, perPage, page), 'get', null, _cb);
  },
  getRequester: (loggerId, requesterId, perPage, page, _cb) => {
    request(routes.getRequester(loggerId, requesterId, perPage, page), 'get', null, _cb);
  },
  getRequesterRequests: (loggerId, requesterId, perPage, page, _cb) => {
    request(routes.getRequesterRequests(loggerId, requesterId, perPage, page), 'get', null, _cb);
  },
  updateRequesterLabel: (loggerId, requesterId, data, _cb) => {
    request(routes.updateRequesterLabel(loggerId, requesterId), 'post', data, _cb);
  },
  updateRequesterGroup: (loggerId, requesterId, data, _cb) => {
    request(routes.updateRequesterGroup(loggerId, requesterId), 'post', data, _cb);
  },
  deleteRequesterLabel: (loggerId, requesterId, _cb) => {
    request(routes.deleteRequesterLabel(loggerId, requesterId), 'delete', null, _cb);
  },
  deleteRequesterGroup: (loggerId, requesterId, _cb) => {
    request(routes.deleteRequesterGroup(loggerId, requesterId), 'delete', null, _cb);
  },
  updateRequesterFlags: (loggerId, requesterId, data, _cb) => {
    request(routes.updateRequesterFlags(loggerId, requesterId), 'post', data, _cb);
  },
  updateRequesterOverride: (loggerId, requesterId, data, _cb) => {
    request(routes.updateRequesterOverride(loggerId, requesterId), 'post', data, _cb);
  },
  // bots
  getBots: (loggerId, perPage, page, _cb) => {
    request(routes.getBots(loggerId, perPage, page), 'get', null, _cb);
  },
  getWhitelist: (loggerId, perPage, page, _cb) => {
    request(routes.getWhitelist(loggerId, perPage, page), 'get', null, _cb);
  },
  getBlacklist: (loggerId, perPage, page, _cb) => {
    request(routes.getBlacklist(loggerId, perPage, page), 'get', null, _cb);
  },
  getLogs: (loggerId, perPage, page, _cb) => {
    request(routes.getLogs(loggerId, perPage, page), 'get', null, _cb);
  },
  getSuspiciousLogs: (loggerId, perPage, page, _cb) => {
    request(routes.getSuspiciousLogs(loggerId, perPage, page), 'get', null, _cb);
  },
  // groups
  getGroups: (loggerId, _cb) => {
    request(routes.getGroups(loggerId), 'get', null, _cb);
  },
  updateGroup: (loggerId, groupId, data, _cb) => {
    request(routes.updateGroup(loggerId, groupId), 'post', data, _cb);
  },
  createGroup: (loggerId, data, _cb) => {
    request(routes.createGroup(loggerId), 'post', data, _cb);
  },
  deleteGroup: (loggerId, groupId, _cb) => {
    request(routes.deleteGroup(loggerId, groupId), 'delete', null, _cb);
  },
  // Crawlers
  getCrawlers: (loggerId, _cb) => {
    request(routes.getCrawlers(loggerId), 'get', null, _cb);
  },
  updateCrawlers: (loggerId, crawlerId, data, _cb) => {
    request(routes.updateCrawlers(loggerId, crawlerId), 'post', data, _cb);
  },
  createCrawler: (loggerId, data, _cb) => {
    request(routes.createCrawler(loggerId), 'post', data, _cb);
  },
  deleteCrawler: (loggerId, crawlerId, _cb) => {
    request(routes.deleteCrawler(loggerId, crawlerId), 'delete', null, _cb);
  },
  // Stopwords
  getStopwords: (loggerId, _cb) => {
    request(routes.getStopwords(loggerId), 'get', null, _cb);
  },
  updateStopwords: (loggerId, stopwordId, data, _cb) => {
    request(routes.updateStopwords(loggerId, stopwordId), 'post', data, _cb);
  },
  createStopword: (loggerId, data, _cb) => {
    request(routes.createStopword(loggerId), 'post', data, _cb);
  },
  deleteStopword: (loggerId, stopwordId, _cb) => {
    request(routes.deleteStopword(loggerId, stopwordId), 'delete', null, _cb);
  },
  // labels
  getLabels: (loggerId, _cb) => {
    request(routes.getLabels(loggerId), 'get', null, _cb);
  },
  updateLabel: (loggerId, labelId, data, _cb) => {
    request(routes.updateLabel(loggerId, labelId), 'post', data, _cb);
  },
  createLabel: (loggerId, data, _cb) => {
    request(routes.createLabel(loggerId), 'post', data, _cb);
  },
  deleteLabel: (loggerId, labelId, _cb) => {
    request(routes.deleteLabel(loggerId, labelId), 'delete', null, _cb);
  },
  // keys
  getKeys: (loggerId, _cb) => {
    request(routes.getKeys(loggerId), 'get', null, _cb);
  },
  createKey: (loggerId, data, _cb) => {
    request(routes.createKey(loggerId), 'post', data, _cb);
  },
  deleteKey: (loggerId, keyId, _cb) => {
    request(routes.deleteKey(loggerId, keyId), 'delete', null, _cb);
  },
  renameKey: (loggerId, keyId, data, _cb) => {
    request(routes.renameKey(loggerId, keyId), 'post', data, _cb);
  },
  // bots
  addToBots: (loggerId, data, _cb) => {
    request(routes.addToBots(loggerId), 'post', data, _cb);
  },
  addToGroup: (loggerId, data, _cb) => {
    request(routes.addToGroup(loggerId), 'post', data, _cb);
  },
};

export default api;
