import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

const Label = ({ color = 'grey', deletable, onDelete, children }) => {

  const getDelete = () => {
    if (deletable) {
      return <button className="delete" onClick={onDelete} />;
    }
  };

  const getContrastingTextColor = (color) => {
    // $orwell-dark-font-color: #22333b;
    // $orwell-light-font-color: #bdbdbd;
    const [darkColor, lightColor] = ['#22333b', '#ffffff'];
    const [r, g, b] = [
      parseInt(color.substring(1, 3), 16), // hexToR
      parseInt(color.substring(3, 5), 16), // hexToG
      parseInt(color.substring(5, 7), 16), // hexToB
    ];
    return (((r * 0.299) + (g * 0.587) + (b * 0.114)) > 186) ?
      darkColor : lightColor;
  };

  const getCustomColorLabel = () => {
    return (
      <span
        className="tag is-rounded is-uppercase"
        style={{
          backgroundColor: color,
          color: getContrastingTextColor(color)
        }}
      >
        {children}
        {getDelete()}
      </span>
    );
  };

  const getSpecificColorLabel = () => {
    return (
      <span className={cx('tag', 'is-rounded', 'is-uppercase', `is-${color}`)}>
        {children}
        {getDelete()}
      </span>

    );
  };

  return (
    <>
      {color.charAt(0) === '#'
        ? getCustomColorLabel()
        : getSpecificColorLabel()}
    </>
  );
};

Label.propTypes = {
  color: PropTypes.string,
  deletable: PropTypes.bool,
  onDelete: PropTypes.func,
  children: PropTypes.any,
};

export default Label;
