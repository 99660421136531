import React, { useState, useEffect, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import api from 'services/api';

import Layout from 'components/Layout/Layout';
import Button from 'components/common/Button';
import Drawer from 'components/common/Drawer';
import CrawlersTable from 'components/Crawlers/CrawlersTable';
import NewCrawlerForm from 'components/Crawlers/NewCrawlerForm';
import { addNotification } from 'actions/globalActions';

const Crawlers = () => {
  const currentLogger = useSelector(state => state.global.currentLogger);
  const dispatch = useDispatch();

  const [isLoading, setIsLoading] = useState(true);
  const [isError, setIsError] = useState(false);
  const [crawlers, setCrawlers] = useState([]);
  const [labels, setLabels] = useState([]);
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [isDrawerLoading, setIsDrawerLoading] = useState(false);
  const [isDrawerError, setIsDrawerError] = useState(false);


  const createNewCrawler = (data) => {
    console.log('data:', data);
    setIsDrawerLoading(true);
    api.createCrawler(currentLogger, data, res => {
      if (res.data.status === 200) {
        setCrawlers([
          ...crawlers,
          res.data.crawler,
        ]);
        dispatch(addNotification({
          title: 'Crawler created successfully',
          type: 'success',
        }));
        setIsDrawerOpen(false);
      } else {
        setIsDrawerError(true);
      }
      setIsDrawerLoading(false);
    });
  };

  const fetchCrawlers = useCallback(() => {
    setIsLoading(true);
    setIsError(false);
    api.getCrawlers(currentLogger, res => {
      if (res.data.status === 200) {
        setCrawlers(res.data.crawlers);
        setLabels(res.data.labels);
      } else {
        setIsError(true);
      }
      setIsLoading(false);
    });
  }, [currentLogger]);

  /**
   * Calls fetchLogs onload.
   */
  useEffect(() => {
    fetchCrawlers();
  }, [fetchCrawlers]);


  return (
    <Layout title={'Crawlers'} loading={isLoading} error={isError} className="is-relative">
      <Drawer isError={isDrawerError} isLoading={isDrawerLoading} isOpen={isDrawerOpen} onClose={() => setIsDrawerOpen(false)}>
        <NewCrawlerForm onSubmitForm={createNewCrawler} labels={labels} />
      </Drawer>
      <div className="columns">
        <div className="column">
          <h1 className="title mb-1">Crawlers</h1>
          <p className="has-text-weight-light">
            You can create and update current crawlers matching rules.
          </p>
        </div>
        <div className="column is-narrow">
          <Button onClick={() => setIsDrawerOpen(true)}>
            New Crawler
          </Button>
        </div>
      </div>
      <CrawlersTable crawlers={crawlers} labels={labels} setCrawlers={setCrawlers} />
    </Layout>
  );
};

export default Crawlers;