import React from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';


import './styles.scss';
import Toast from '../../common/Toast';

const Notifications = ({ position = 'bottom-right' }) => {

  const notifications = useSelector(state => state.global.notifications);

  const drawToasts = () => (
    notifications.map(toast => (
      <Toast
        key={toast.id}
        id={toast.id}
        title={toast.title}
        description={toast.description}
        type={toast.type}
      />
    ))
  );

  return (
    <>
      <div className={`toast-container ${position}`}>
        {drawToasts()}
      </div>
    </>
  );
};

Notifications.propTypes = {
  position: PropTypes.string,
};

export default Notifications;
