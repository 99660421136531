import React from 'react';
import PropTypes from 'prop-types';
import StopwordsTableRow from '../StopwordsTableRow';

const StopwordsTable = ({ stopwords = [], labels = [], setStopwords }) => {
  const drawStopwordRows = () => (
    stopwords.map((stopword) => (
      <StopwordsTableRow
        key={stopword.id}
        stopword={stopword}
        stopwords={stopwords}
        labels={labels}
        setStopwords={setStopwords}
      />
    ))
  );

  return (
    <table className="table is-fullwidth is-narrow">
      <thead>
        <tr>
          <th>Stopword</th>
          <th>Safewords</th>
          <th>Label</th>
          <th></th>
          <th></th>
        </tr>
      </thead>
      <tbody>
        {drawStopwordRows()}
      </tbody>
    </table>
  );
};

StopwordsTable.propTypes = {
  stopwords: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string,
    dnsNames: PropTypes.array,
    uaNames: PropTypes.array,
    labelId: PropTypes.string,
    labelName: PropTypes.string,
    labelColor: PropTypes.string
  })),
  labels: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string,
    color: PropTypes.string
  })),
  setStopwords: PropTypes.func
};

export default StopwordsTable;
