import React, { useEffect, useState, useCallback } from 'react';
import { useSelector } from 'react-redux';

import Layout from 'components/Layout/Layout';
import Button from 'components/common/Button';

import api from 'services/api';
import ProcessingTimeChart from 'components/Performance/ProcessingTimeChart';
import SlowPagesTable from 'components/Performance/SlowPagesTable';
import QueriesChart from 'components/Performance/QueriesChart';
import QueriedPagesTable from 'components/Performance/QueriedPagesTable';

const filters = [
  { title: 'Last 30 minutes', value: '30', timeframe: 'minutes' },
  { title: 'Last 24 hours', value: '24', timeframe: 'hours' },
  { title: 'All time', value: '87600', timeframe: 'hours' }
];

const Performance = () => {
  const currentLogger = useSelector((state) => state.global.currentLogger);

  const [processingTime, setProcessingTime] = useState([]);
  const [slowPages, setSlowPages] = useState([]);
  const [queries, setQueries] = useState([]);
  const [queriedPages, setQueriedPages] = useState([]);
  // state
  const [isLoading, setIsLoading] = useState(true);
  const [isError, setIsError] = useState(true);
  const [activeFilter, setActiveFilter] = useState(1);

  /**
   * Fetches for performance data.
   */
  const fetchPerformance = useCallback(() => {
    setIsLoading(true);
    api.getPerformance(
      currentLogger,
      filters[activeFilter].value,
      filters[activeFilter].timeframe,
      (res) => {
        setIsLoading(false);
        if (res.data.status === 200) {
          setProcessingTime(res.data.processingTime);
          setSlowPages(res.data.slowPages);
          setQueries(res.data.queries);
          setQueriedPages(res.data.queriedPages);
        } else {
          setIsError(true);
        }
      });
  }, [currentLogger, activeFilter]);

  /**
   * Calls fetchPerformance onload and when filter changes.
   */
  useEffect(() => {
    fetchPerformance();
  }, [fetchPerformance]);

  /**
   * Gets the filter buttons.
   */
  const getFilterBtns = () => {
    return filters.map((filter, index) => {

      // Updates the currently active filter
      const changeFilter = () => {
        setActiveFilter(index);
      };

      return (
        <Button
          key={index}
          rounded
          onClick={changeFilter}
          type={activeFilter === index ? 'filled' : 'outlined'}
          className="activity-filter-btn"
        >
          {filter.title}
        </Button>
      );
    });
  };

  return (
    <Layout title={'Performance'} loading={isLoading} isError={isError}>
      <div className="activity-filter-row">
        {getFilterBtns()}
      </div>

      <div className="columns is-desktop is-multiline">
        <div className="column is-two-thirds-widescreen is-full-desktop">
          <ProcessingTimeChart chartData={processingTime} />
        </div>
        <div className="column is-one-third-widescreen is-full-desktop">
          <SlowPagesTable slowPages={slowPages} />
        </div>
      </div>

      <div className="columns is-desktop is-multiline">
        <div className="column is-two-thirds-widescreen is-full-desktop">
          <QueriesChart chartData={queries} />
        </div>
        <div className="column is-one-third-widescreen is-full-desktop">
          <QueriedPagesTable queriedPages={queriedPages} />
        </div>
      </div>
    </Layout>
  );
};

export default Performance;
