import React from 'react';
import PropTypes from 'prop-types';


const ChartLabel = ({ label, color }) => (
  <>
    <span
      className="is-inline-block mr-3"
      style={{
        backgroundColor: color,
        width: '1.5rem',
        height: '1.5rem',
        borderRadius: '6px',
      }}
    />
    {label}
  </>
);

ChartLabel.propTypes = {
  label: PropTypes.string,
  color: PropTypes.string,
};

export default ChartLabel;
