import React from 'react';
import { Link } from 'react-router-dom';

import routes from 'routes';

import './styles.scss';

const NewLogger = () => {
  return (
    <div className="column container-wrapper is-one-third-tablet is-4-desktop is-3-widescreen is-2-fullhd">
      <div className="container">
        <div className="logger-btn">
          <div className="wrap">
            <Link to={routes.createLogger.def}>
              <div className="add-btn">+</div>
            </Link>
          </div>
        </div>
        <p>Create new Logger</p>
      </div>
    </div>
  );
};

export default NewLogger;
