import React from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

import routes from 'routes';
import LoggerBtn from 'components/common/LoggerBtn';

import './styles.scss';

const Loggerbar = () => {
  const currentLogger = useSelector(state => state.global.currentLogger);

  const loggers = useSelector(state => state.global.loggers);

  const allLoggers = loggers.map(l => {
    return (
      <LoggerBtn
        key={l.id}
        loggerId={l.id}
        loggerName={l.name}
        loggerNotifications={l.notifications}
        selected={currentLogger === l.id}
      />
    );
  });

  return (
    <div className="loggerbar">
      <div className="logger-container">
        {allLoggers}
        <div className="logger-btn">
          <div className="wrap">
            <Link to={routes.createLogger.def}>
              <div className="add-btn">+</div>
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Loggerbar;
