import React, { useState } from 'react';
import cx from 'classnames';
import PropTypes from 'prop-types';

import Button from '../Button';

const DropDown = ({ loading, className, children }) => {

  const [collapsed, setCollapsed] = useState(true);

  const handleBlur = () => {
    if (!collapsed) {
      setCollapsed(true);
    }
  };

  // const getDropDownItems = () =>
  //   nameValuePairs.map((nameValuePair, i) => (
  //     <a href="#" className="dropdown-item has-text-left" key={i} onMouseDown={() => callBackOnSelect(nameValuePair)}>
  //       <Label
  //         color={nameValuePair.color}
  //         textColor="white"
  //       >
  //         {nameValuePair.name}
  //       </Label>
  //     </a>
  //   ));

  return (
    <div
      className={cx('dropdown', 'is-right', 'is-hovered', className, {
        'is-active': !collapsed
      })}
    >
      <div className="dropdown-trigger">
        <Button
          loading={loading}
          size="small"
          type="text"
          color="black"
          onClick={() => setCollapsed(!collapsed)}
        >
          <span className="icon is-small">
            <i className="fas fa-ellipsis-h"></i>
          </span>
        </Button>
      </div>
      <div
        onBlur={handleBlur}
        className="dropdown-menu"
      >
        <div className="dropdown-content">
          {children}
        </div>
      </div>
    </div>
  );
};

DropDown.propTypes = {
  className: PropTypes.string,
  loading: PropTypes.bool,
  children: PropTypes.any
};

export default DropDown;
