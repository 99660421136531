import React from 'react';
import PropTypes from 'prop-types';

import { truncateDecimalNumber } from 'services/valueFormatter';

import Card from 'components/common/Card';
import Variation from 'components/common/Variation';

import './styles.scss';

const FlagsCard = ({ blacklist, whitelist, bots }) => {

  return (
    <Card>
      <h3 className="has-text-weight-bold has-text-light mb-2">
        Flags
      </h3>
      <div>
        <h4>Blacklist</h4>
        <progress
          className="flag-card-progress progress is-primary mb-2"
          value={blacklist.value}
          max="100"
        >
          {blacklist.value}
          %
        </progress>
        <div className="is-flex is-justify-content-space-between">
          <span
            className="is-size-4 has-text-primary"
          >
            {truncateDecimalNumber(blacklist.value)}
            %
          </span>
          <Variation variation={blacklist.variation} />
        </div>
      </div>

      <div>
        <h4>Whitelist</h4>
        <progress
          className="flag-card-progress progress is-primary mb-2"
          value={whitelist.value}
          max="100"
        >
          {whitelist.value}
          %
        </progress>
        <div className="is-flex is-justify-content-space-between">
          <span
            className="is-size-4 has-text-primary"
          >
            {truncateDecimalNumber(whitelist.value)}
            %
          </span>
          <Variation variation={whitelist.variation} />
        </div>
      </div>

      <div>
        <h4>Bots</h4>
        <progress
          className="flag-card-progress progress is-primary mb-2"
          value={bots.value}
          max="100"
        >
          {bots.value}
          %
        </progress>
        <div className="is-flex is-justify-content-space-between">
          <span
            className="is-size-4 has-text-primary"
          >
            {truncateDecimalNumber(bots.value)}
            %
          </span>
          <Variation variation={bots.variation} />
        </div>
      </div>
    </Card>
  );
};

FlagsCard.propTypes = {
  blacklist: PropTypes.shape({
    value: PropTypes.any,
    variation: PropTypes.number
  }),
  whitelist: PropTypes.shape({
    value: PropTypes.any,
    variation: PropTypes.number
  }),
  bots: PropTypes.shape({
    value: PropTypes.any,
    variation: PropTypes.number
  }),
};

export default FlagsCard;
