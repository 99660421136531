import React, { useState } from 'react';
import PropTypes from 'prop-types';

import Card from 'components/common/Card';
import { truncateIntegerNumber } from 'services/valueFormatter';
import Button from 'components/common/Button';
import Icon from 'components/common/Icon';

const SlowPagesTable = ({ slowPages = [] }) => {
  const [page, setPage] = useState(0); // page 0-4
  const perPage = 10;
  const maxPage = Math.ceil(slowPages.length / perPage) - 1;

  const drawCountryRows = () => (
    slowPages.map(({ name, value }, index) => {
      if (index >= page * perPage && index < (page + 1) * perPage) {
        return (
          <tr
            key={name}
          >
            <td>
              <p className="is-size-7 has-tooltip-top" data-tooltip={name}>
                <a href={name} target="_blank" rel="noreferrer" className="truncate is-clipped">
                  {name}
                </a>
              </p>
              <p className="is-size-7">
                <span className="has-text-weight-bold">
                  {truncateIntegerNumber(value)}
                </span>
                &nbsp;ms
              </p>
            </td>
          </tr>
        );
      }
    })
  );

  const drawPagination = () => {
    if (slowPages.length !== 0) {
      return (
        <div className="is-flex is-justify-content-center">
          <Button
            type="text"
            size="small"
            className="mr-2"
            onClick={() => page === 0 ? '' : setPage(page - 1)}
          >
            <Icon
              color={page === 0 ? 'grey' : 'primary'}
              icon="fa-chevron-left"
            />
          </Button>
          <Button
            type="text"
            size="small"
            className="ml-2"
            onClick={() => page === maxPage ? '' : setPage(page + 1)}
          >
            <Icon
              color={page === maxPage ? 'grey' : 'primary'}
              icon="fa-chevron-right"
            />
          </Button>
        </div>
      );
    }
  };

  return (
    <Card>
      <h3 className="has-text-weight-bold has-text-light">
        Top 50 Slow Pages
      </h3>
      <div style={{ height: 500 }} className="is-clipped">
        <table className="table is-fullwidth is-narrow">
          <tbody>
            {drawCountryRows()}
          </tbody>
        </table>
        {drawPagination()}
      </div>
    </Card>
  );
};

SlowPagesTable.propTypes = {
  slowPages: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      value: PropTypes.number,
    })
  ),
};

export default SlowPagesTable;
