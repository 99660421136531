import React from 'react';

import Cross from 'components/common/Cross';

import './styles.scss';

const LayoutError = () => {
  return (
    <div className="errorscreen-container">
      <div className="errorscreen-wrap">
        <Cross className="errorscreen-cross" />
        <div className="errorscreen-label">
          Something went wrong, please reload the page.
        </div>
      </div>
    </div>
  );
};

export default LayoutError;
