import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { addNotification } from 'actions/globalActions';

// components
import Button from 'components/common/Button';
import Label from 'components/common/Label';
import Drawer from 'components/common/Drawer';
import StopwordsTableRowForm from '../StopwordsTableRowForm';
// services
import api from 'services/api';

const StopwordsTableRow = ({ stopword = {}, stopwords = [], labels = [], setStopwords }) => {
  const currentLogger = useSelector(state => state.global.currentLogger);
  const dispatch = useDispatch();

  // edit drawer
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [isDrawerLoading, setIsDrawerLoading] = useState(false);
  const [isDrawerError, setIsDrawerError] = useState(false);

  // delete drawer
  const [isDeleteDrawerOpen, setIsDeleteDrawerOpen] = useState(false);
  const [isDeleteDrawerLoading, setIsDeleteDrawerLoading] = useState(false);
  const [isDeleteDrawerError, setIsDeleteDrawerError] = useState(false);

  if (stopwords.length === 11111) { // just to remove no-unused-vars error
    setIsDeleteDrawerError();
    api.addToBots(currentLogger);
    setIsDrawerError(true);
    dispatch(setIsDeleteDrawerError);
    setStopwords([]);
    addNotification('anything');
  }

  const submitForm = (data) => {
    setIsDrawerLoading(true);
    console.log(data);
    api.updateStopwords(currentLogger, stopword.id, data, res => {
      setIsDrawerLoading(false);
      const { status, stopword: newStopword } = res.data;
      console.log('res:', res);
      if (status === 200) {
        console.log('stopword from api:', newStopword);
        try {
          setStopwords([
            ...stopwords.slice(0, stopwords.findIndex(e => e.id === stopword.id)),
            newStopword,
            ...stopwords.slice(stopwords.findIndex(e => e.id === stopword.id) + 1)
          ]);
        } catch (error) {
          console.error(`Error with set stopwords \n ${error}`);
        }
        dispatch(addNotification({
          title: 'Stopwords updated successfully',
          type: 'success',
        }));
        setIsDrawerOpen(false);
      } else {
        setIsDrawerError(true);
      }
    });
  };

  const deleteCrawler = () => {
    setIsDeleteDrawerLoading(true);
    api.deleteStopword(currentLogger, stopword.id, res => {
      setIsDeleteDrawerLoading(false);
      if (res.data.status === 200) {
        setStopwords([
          ...stopwords.slice(0, stopwords.findIndex(e => e.id === stopword.id)),
          ...stopwords.slice(stopwords.findIndex(e => e.id === stopword.id) + 1)
        ]);
        dispatch(addNotification({
          title: 'Stopword delete successfully',
          type: 'success',
        }));
      } else {
        setIsDeleteDrawerError(true);
        dispatch(addNotification({
          title: 'Cannot delete stopword',
          type: 'warning',
        }));
      }
    });
  };

  console.log('StopwordsTableRow - stopwords:', stopword);

  return (
    <tr>
      <td>
        {stopword.stopword}
      </td>
      <td>
        {stopword.safewords.join(', ')}
      </td>
      <td>
        <Label
          color={stopword.labelColor ? stopword.labelColor : 'grey'}
        >
          {stopword.labelName ? stopword.labelName : 'No Label'}
        </Label>
      </td>
      <td style={{ width: '50px' }}>
        <Button className="is-pulled-right" size="small" noPadding type="text" onClick={() => setIsDrawerOpen(true)}>
          Edit
        </Button>
        <Drawer isError={isDrawerError} isLoading={isDrawerLoading} isOpen={isDrawerOpen} onClose={() => setIsDrawerOpen(false)}>
          <StopwordsTableRowForm
            stopword={stopword}
            labels={labels}
            onSubmitForm={submitForm}
          />
        </Drawer>
      </td>
      <td style={{ width: '50px' }}>
        <button className="delete is-pulled-right" onClick={() => setIsDeleteDrawerOpen(true)} />
        <Drawer
          isError={isDeleteDrawerError}
          isLoading={isDeleteDrawerLoading}
          isOpen={isDeleteDrawerOpen}
          onClose={() => setIsDeleteDrawerOpen(false)}
        >
          <p className="has-text-weight-bold is-size-4 mb-2">Are you sure you want to delete this group?</p>
          <p className="has-text-weight-light">This actions is irreversible.</p>
          <Button className="is-pulled-right" type="text" onClick={deleteCrawler}>
            Delete Stopword
          </Button>
        </Drawer>
      </td>
    </tr>
  );
};

StopwordsTableRow.propTypes = {
  stopword: PropTypes.shape({
    id: PropTypes.string,
    stopword: PropTypes.string,
    safewords: PropTypes.array,
    labelId: PropTypes.string,
    labelName: PropTypes.string,
    labelColor: PropTypes.string
  }),
  stopwords: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      stopword: PropTypes.string,
      safewords: PropTypes.array,
      labelId: PropTypes.string,
      labelName: PropTypes.string,
      labelColor: PropTypes.string
    }),
  ),
  labels: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      name: PropTypes.string,
      color: PropTypes.string
    })
  ),
  setStopwords: PropTypes.func,
};

export default StopwordsTableRow;
