import React from 'react';
import { useSelector } from 'react-redux';

import Layout from 'components/Layout/Layout';
import LoggerCard from 'components/MyLoggers/LoggerCard';
import NewLogger from 'components/MyLoggers/NewLogger';

import './styles.scss';

const MyLoggers = () => {
  const loggers = useSelector((state) => state.global.loggers);

  const allLoggers = loggers.map((l) => {
    return (
      <div
        key={l.id}
        className="column is-one-third-tablet is-4-desktop is-3-widescreen is-2-fullhd is-fullwidth"
      >
        <LoggerCard key={l.id} loggerId={l.id} loggerName={l.name} loggerNotifications={l.notifications} loggerDescription={l.description}></LoggerCard>
      </div>
    );
  });

  return (
    <Layout title="My Loggers" noSidebar navbarLogo>
      <div className="columns is-multiline">
        {allLoggers}
        <NewLogger />
      </div>
    </Layout>
  );
};

export default MyLoggers;
