import React, { useState } from 'react';
import PropTypes from 'prop-types';

import ReactTooltip from 'react-tooltip';

import Card from 'components/common/Card';
import MapChart from 'components/common/MapChart';

const MapChartCard = ({ countries }) => {
  const [content, setContent] = useState('');

  return (
    <Card>
      <h3 className="has-text-weight-bold has-text-light mb-2">
        World Map
      </h3>
      <MapChart
        setTooltipContent={setContent}
        countries={countries}
        width={600}
        height={300}
      />
      <ReactTooltip>
        {content}
      </ReactTooltip>
    </Card>
  );
};


MapChartCard.propTypes = {
  countries: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      count: PropTypes.string
    })
  ),
};

export default MapChartCard;
