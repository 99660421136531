import React from 'react';
import PropTypes from 'prop-types';

import Card from '../Card';
import Icon from '../Icon';
import Stamp from '../Stamp';

const MetricCard = ({ title, value, icon, variation, truncate = true }) => {

  const drawIcon = () => {
    if (icon) {
      return (
        <Icon icon={icon} size="larger" color="primary" />
      );
    }
  };


  return (
    <Card>
      <div className="is-flex is-align-items-center">
        {drawIcon()}
        <Stamp
          title={title}
          value={value}
          className="pl-2 is-flex-grow-1"
          variation={variation}
          truncate={truncate}
        />
      </div>
    </Card>
  );
};

MetricCard.propTypes = {
  title: PropTypes.string,
  value: PropTypes.any,
  icon: PropTypes.string,
  variation: PropTypes.number,
  truncate: PropTypes.bool,
};

export default MetricCard;
