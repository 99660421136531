import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

import Variation from '../Variation';

const Stamp = ({ title, value, variation, size = 4, truncate = true, className }) => {

  const drawVariation = () => {
    // variation can be '0' which is falsy
    if (variation !== null && variation !== undefined) {
      return (
        <Variation variation={variation} />
      );
    }
  };

  return (
    <div className={cx(className, 'is-block', { 'truncate': truncate })}>
      <h3 className="has-text-weight-bold has-text-light">
        {title}
      </h3>
      <div className="is-flex is-justify-content-space-between">
        <p className={cx(`is-size-${size}`, { 'truncate': truncate })}>
          {value !== null && value !== undefined && value !== '' ? value : 'N/A'}
        </p>
        {drawVariation()}
      </div>
    </div>
  );
};

Stamp.propTypes = {
  title: PropTypes.string,
  value: PropTypes.any,
  size: PropTypes.any,
  truncate: PropTypes.bool,
  variation: PropTypes.number,
  className: PropTypes.string,
};

export default Stamp;
