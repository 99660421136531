import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { addNotification } from 'actions/globalActions';

// components
import Button from 'components/common/Button';
import Label from 'components/common/Label';
import Drawer from 'components/common/Drawer';
import CrawlersTableRowForm from '../CrawlersTableRowForm';
// services
import api from 'services/api';

const CrawlersTableRow = ({ crawler = {}, crawlers = [], labels = [], setCrawlers }) => {
  const currentLogger = useSelector(state => state.global.currentLogger);
  const dispatch = useDispatch();

  // edit drawer
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [isDrawerLoading, setIsDrawerLoading] = useState(false);
  const [isDrawerError, setIsDrawerError] = useState(false);

  // delete drawer
  const [isDeleteDrawerOpen, setIsDeleteDrawerOpen] = useState(false);
  const [isDeleteDrawerLoading, setIsDeleteDrawerLoading] = useState(false);
  const [isDeleteDrawerError, setIsDeleteDrawerError] = useState(false);

  if (crawlers.length === 11111) { // just to remove no-unused-vars error
    setIsDeleteDrawerError();
    api.addToBots(currentLogger);
  }

  const submitForm = (data) => {
    setIsDrawerLoading(true);
    console.log(data);
    api.updateCrawlers(currentLogger, crawler.id, data, res => {
      setIsDrawerLoading(false);
      const { status, crawler: newCrawler } = res.data;
      console.log('res:', res);
      if (status === 200) {
        try {
          setCrawlers([
            ...crawlers.slice(0, crawlers.findIndex(e => e.id === crawler.id)),
            newCrawler,
            ...crawlers.slice(crawlers.findIndex(e => e.id === crawler.id) + 1)
          ]);
        } catch (error) {
          console.error(`Error with set crawlers \n ${error}`);
        }
        dispatch(addNotification({
          title: 'Crawler updated successfully',
          type: 'success',
        }));
        setIsDrawerOpen(false);
      } else {
        setIsDrawerError(true);
      }
    });
  };

  const deleteCrawler = () => {
    setIsDeleteDrawerLoading(true);
    api.deleteCrawler(currentLogger, crawler.id, res => {
      setIsDeleteDrawerLoading(false);
      if (res.data.status === 200) {
        setCrawlers([
          ...crawlers.slice(0, crawlers.findIndex(e => e.id === crawler.id)),
          ...crawlers.slice(crawlers.findIndex(e => e.id === crawler.id) + 1)
        ]);
        dispatch(addNotification({
          title: 'Crawler delete successfully',
          type: 'success',
        }));
      } else {
        setIsDeleteDrawerError(true);
        dispatch(addNotification({
          title: 'Cannot delete crawler',
          type: 'warning',
        }));
      }
    });
  };

  return (
    <tr>
      <td>
        {crawler.name}
      </td>
      <td>
        {crawler.dnsNames.join(', ')}
      </td>
      <td>
        {crawler.uaNames.join(', ')}
      </td>
      <td>
        <Label
          color={crawler.labelColor ? crawler.labelColor : 'grey'}
        >
          {crawler.labelName ? crawler.labelName : 'No Label'}
        </Label>
      </td>
      <td style={{ width: '50px' }}>
        <Button className="is-pulled-right" size="small" noPadding type="text" onClick={() => setIsDrawerOpen(true)}>
          Edit
        </Button>
        <Drawer isError={isDrawerError} isLoading={isDrawerLoading} isOpen={isDrawerOpen} onClose={() => setIsDrawerOpen(false)}>
          <CrawlersTableRowForm
            crawler={crawler}
            labels={labels}
            onSubmitForm={submitForm}
          />
        </Drawer>
      </td>
      <td style={{ width: '50px' }}>
        <button className="delete is-pulled-right" onClick={() => setIsDeleteDrawerOpen(true)} />
        <Drawer
          isError={isDeleteDrawerError}
          isLoading={isDeleteDrawerLoading}
          isOpen={isDeleteDrawerOpen}
          onClose={() => setIsDeleteDrawerOpen(false)}
        >
          <p className="has-text-weight-bold is-size-4 mb-2">Are you sure you want to delete this group?</p>
          <p className="has-text-weight-light">This actions is irreversible.</p>
          <Button className="is-pulled-right" type="text" onClick={deleteCrawler}>
            Delete Crawler
          </Button>
        </Drawer>
      </td>
    </tr>
  );
};

CrawlersTableRow.propTypes = {
  crawler: PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string,
    dnsNames: PropTypes.array,
    uaNames: PropTypes.array,
    labelId: PropTypes.string,
    labelName: PropTypes.string,
    labelColor: PropTypes.string
  }),
  crawlers: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      name: PropTypes.string,
      dnsNames: PropTypes.array,
      uaNames: PropTypes.array,
      labelId: PropTypes.string,
      labelName: PropTypes.string,
      labelColor: PropTypes.string
    }),
  ),
  labels: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      name: PropTypes.string,
      color: PropTypes.string
    })
  ),
  setCrawlers: PropTypes.func,
};

export default CrawlersTableRow;
