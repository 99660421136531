import React from 'react';
import PropTypes from 'prop-types';

import Card from 'components/common/Card';
import BarChart from 'components/common/BarChart';

const ProcessingTimeChart = ({ chartData = [] }) => {
  const backgroundColor = '#b0413e';
  // sort current hour should be the last element
  const currentHour = new Date().getHours();
  const sortedChartData = [
    ...chartData.filter(elem => elem.label > currentHour),
    ...chartData.filter(elem => elem.label <= currentHour)
  ];
  const data = sortedChartData.map((elem) => elem.value);
  const labels = sortedChartData.map((elem) => elem.label);

  return (
    <Card>
      <h3 className="has-text-weight-bold has-text-light">
        Total Processing Time (ms)
      </h3>
      <BarChart
        id={'processing-time-bar-chart'}
        data={data}
        labels={labels}
        backgroundColor={backgroundColor}
        height={500}
      />
    </Card>
  );
};

ProcessingTimeChart.propTypes = {
  chartData: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.number,
      label: PropTypes.number,
    })
  ),
};

export default ProcessingTimeChart;
