import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import Layout from 'components/Layout/Layout';
import Drawer from 'components/common/Drawer';
import api from 'services/api';
import Button from 'components/common/Button';
import LabelsTable from 'components/Labels/LabelsTable';
import NewLabelForm from 'components/Labels/NewLabelForm';
import { addNotification } from 'actions/globalActions';

const Labels = () => {
  const currentLogger = useSelector(state => state.global.currentLogger);
  const dispatch = useDispatch();

  const [isLoading, setIsLoading] = useState(true);
  const [isError, setIsError] = useState(false);
  const [labels, setLabels] = useState([]);
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [isDrawerLoading, setIsDrawerLoading] = useState(false);
  const [isDrawerError, setIsDrawerError] = useState(false);

  const createNewLabel = (data) => {
    setIsDrawerLoading(true);
    api.createLabel(currentLogger, data, res => {
      if (res.data.status === 200) {
        setLabels([
          ...labels,
          res.data.label
        ]);
        dispatch(addNotification({
          title: 'Label created successfully',
          type: 'success',
        }));
        setIsDrawerOpen(false);
      } else {
        setIsDrawerError(true);
      }
      setIsDrawerLoading(false);
    });
  };

  const fetchLabels = useCallback(() => {
    setIsLoading(true);
    setIsError(false);
    api.getLabels(currentLogger, res => {
      if (res.data.status === 200) {
        setLabels(res.data.labels.sort((a, b) => b.priority - a.priority));
      } else {
        setIsError(true);
      }
      setIsLoading(false);
    });
  }, [currentLogger]);

  /**
   * Calls fetchLabels onload.
   */
  useEffect(() => {
    fetchLabels();
  }, [fetchLabels]);

  return (
    <Layout title={'Labels'} loading={isLoading} error={isError} className="is-relative">
      <Drawer isError={isDrawerError} isLoading={isDrawerLoading} isOpen={isDrawerOpen} onClose={() => setIsDrawerOpen(false)}>
        <NewLabelForm onSubmitForm={createNewLabel} priorities={labels.map(e => e.priority)} />
      </Drawer>
      <div className="columns">
        <div className="column">
          <h1 className="title mb-1">Labels</h1>
          <p className="has-text-weight-light">
            You can create Labels with custom names and colors. After that,
            at most one Label can be added to an IP for easier aggregation.
          </p>
        </div>
        <div className="column is-narrow">
          <Button onClick={() => setIsDrawerOpen(true)}>
            New Label
          </Button>
        </div>
      </div>
      <LabelsTable labels={labels} setLabels={setLabels} />
    </Layout>
  );
};

export default Labels;
