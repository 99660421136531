import React, { useEffect } from 'react';
import PropTypes from 'prop-types';

import Chart from 'chart.js/auto';

const defaultColorPallette = [
  '#7c98ab',
  '#9c9359',
  '#dbbc8e',
  '#ae8a8c',
  '#8c7386',
  '#d08d8b',
  '#766161',
  '#a9c8c0',
  '#a3cb8f',
];

const DoughnutChart = ({
  id = 'doughnut-chart',
  height = 300,
  width,
  circumference = 360,
  rotation = 0,
  cutout = '80%',
  labels = [],
  data = [],
  backgroundColor = defaultColorPallette
}) => {

  useEffect(() => {
    let actualData = [];
    let actualLabels = [];
    let actualBackgroundColor = [];

    // check if no data
    const emptyArray = data.length === 0;
    const allZeros = !data.find(elem => elem !== 0);
    if (emptyArray || allZeros) {
      actualData = [1];
      actualLabels = ['No Data'];
      actualBackgroundColor = ['#dedcdc'];
    } else {
      actualData = data;
      actualLabels = labels;
      actualBackgroundColor = backgroundColor;
    }

    const doughnutChart = new Chart(id, {
      type: 'doughnut',
      data: {
        labels: actualLabels,
        datasets: [
          {
            data: actualData,
            backgroundColor: actualBackgroundColor,
            borderRadius: 6,
          },
        ],
        hoverOffset: 4
      },
      options: {
        responsive: true,
        maintainAspectRatio: false,
        cutout,
        circumference,
        rotation,
        plugins: {
          legend: {
            display: false,
          },
        },
        // tooltips: {
        //   callbacks: {
        //     label: function (tooltipItem, data) {
        //       const dataset = data.datasets[tooltipItem.datasetIndex];
        //       const total = dataset.data.reduce(function (
        //         previousValue,
        //         currVal
        //       ) {
        //         return parseFloat(previousValue) + parseFloat(currVal);
        //       });

        //       const currentValue = dataset.data[tooltipItem.index];
        //       const percentage = (currentValue / total) * 100;

        //       return (
        //         ' ' +
        //         currentValue +
        //         ' requests (' +
        //         percentage.toFixed(2) +
        //         '%)'
        //       );
        //     },
        //   },
        // },
      },
    });

    // when component unmounts
    return () => {
      doughnutChart.destroy();
    };
  }, [backgroundColor, circumference, cutout, data, id, labels, rotation]);

  return (
    <div className="chart-container">
      <canvas id={id} height={height} width={width} />
    </div>
  );
};

DoughnutChart.propTypes = {
  id: PropTypes.string,
  cutout: PropTypes.any,
  circumference: PropTypes.any,
  rotation: PropTypes.any,
  data: PropTypes.arrayOf(PropTypes.any),
  labels: PropTypes.arrayOf(PropTypes.any),
  height: PropTypes.number,
  width: PropTypes.number,
  backgroundColor: PropTypes.any,
};

export default DoughnutChart;
