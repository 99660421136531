import React from 'react';
import PropTypes from 'prop-types';
import CrawlersTableRow from '../CrawlersTableRow';

const CrawlersTable = ({ crawlers = [], labels = [], setCrawlers }) => {

  const drawCrawlerRows = () => (
    crawlers.map((crawler) => (
      <CrawlersTableRow
        key={crawler.id}
        crawler={crawler}
        crawlers={crawlers}
        labels={labels}
        setCrawlers={setCrawlers}
      />
    ))
  );

  return (
    <table className="table is-fullwidth is-narrow">
      <thead>
        <tr>
          <th>Name</th>
          <th>DNS Names</th>
          <th>User Agent Names</th>
          <th>Label</th>
          <th></th>
          <th></th>
        </tr>
      </thead>
      <tbody>
        {drawCrawlerRows()}
      </tbody>
    </table>
  );
};

CrawlersTable.propTypes = {
  crawlers: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string,
    dnsNames: PropTypes.array,
    uaNames: PropTypes.array,
    labelId: PropTypes.string,
    labelName: PropTypes.string,
    labelColor: PropTypes.string
  })),
  labels: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string,
    color: PropTypes.string
  })),
  setCrawlers: PropTypes.func
};

export default CrawlersTable;
