import { useCallback } from 'react';

const usePage = (title) => {
  const projectName = 'Project Orwell';

  const setPageTitle = useCallback((newTitle) => document.title = `${newTitle} | ${projectName}`, []);

  if (title) {
    setPageTitle(title);
  } else {
    document.title = projectName;
  }

  return {
    setPageTitle,
  };
};

export default usePage;
