import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import cx from 'classnames';

import { addNotification, setUpdateLoggers } from 'actions/globalActions';
import api from 'services/api';
import routes from 'routes';

import Label from 'components/common/Label';
import Layout from 'components/Layout/Layout';
import Divider from 'components/common/Divider';
import Button from 'components/common/Button';
import Icon from 'components/common/Icon';
import Drawer from 'components/common/Drawer';
import DropDown from 'components/common/Dropdown';

const Settings = () => {
  const dispatch = useDispatch();
  const history = useHistory();

  const characterLimit = 255;
  const currentLogger = useSelector((state) => state.global.currentLogger);

  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);

  const [name, setName] = useState('');
  const [description, setDescription] = useState('');

  const [labels, setLabels] = useState([]);

  const [crawlerLabel, setCrawlerLabel] = useState({});
  const [crawlerLabelFilter, setCrawlerLabelFilter] = useState('');

  const [regexLabel, setRegexLabel] = useState({});
  const [regexLabelFilter, setRegexLabelFilter] = useState('');

  const [isUpdateLoading, setIsUpdateLoading] = useState(false);
  const [isDeleteDrawerOpen, setIsDeleteDrawerOpen] = useState(false);
  const [isDeleteDrawerLoading, setIsDeleteDrawerLoading] = useState(false);
  const [isDeleteDrawerError, setIsDeleteDrawerError] = useState(false);

  const fetchLabels = useCallback(() => {
    setIsLoading(true);
    setIsError(false);

    api.getLabels(currentLogger, (res) => {
      const { labels, status } = res.data;
      if (status === 200) {
        setLabels(labels);
      } else {
        setIsError(true);
      }
    });
  }, [currentLogger]);

  const fetchLogger = useCallback(() => {

    const getLabelFromId = (id) => {
      for (const label of labels) {
        if (label.id === id) {
          return label;
        }
      }
      return {};
    };

    setIsLoading(true);
    setIsError(false);
    api.getLogger(currentLogger, (res) => {
      const { logger, status } = res.data;
      console.log('logger api responded');
      if (status === 200) {
        setName(logger.name);
        setDescription(logger.description);
        setCrawlerLabel(getLabelFromId(logger.crawlerLabelId));
        setRegexLabel(getLabelFromId(logger.regexLabelId));
      } else {
        setIsError(true);
      }
      setIsLoading(false);
    });
  }, [currentLogger, labels]);

  const getCrawlersLabelOptions = () =>
    labels
      .filter(({ name }) =>
        name.toLowerCase().startsWith(crawlerLabelFilter.toLowerCase())
      )
      .map(({ id, color, name }) => (
        <a
          href="#"
          className="dropdown-item has-text-left"
          key={id}
          onMouseDown={() => setCrawlerLabel({ id, color, name })}
        >
          <Label color={color}>
            {name}
          </Label>
        </a>
      ));

  const getRegexLabelOptions = () =>
    labels
      .filter(({ name }) =>
        name.toLowerCase().startsWith(regexLabelFilter.toLowerCase())
      )
      .map(({ id, color, name }) => (
        <a
          href="#"
          className="dropdown-item has-text-left"
          key={id}
          onMouseDown={() => setRegexLabel({ id, color, name })}
        >
          <Label color={color}>
            {name}
          </Label>
        </a>
      ));

  /**
   * Calls fetchRequester onload.
   */
  useEffect(() => {
    fetchLabels();
  }, [fetchLabels]);

  useEffect(() => {
    if (labels.length > 0) {
      fetchLogger();
    }
  }, [fetchLogger, labels]);

  const handleNameChange = (e) => {
    const { value } = e.target;
    setName(value);
  };

  const handleDescriptionChange = (e) => {
    const { value } = e.target;
    setDescription(value);
  };

  const validateName = (nameToValidate) => {
    if (!nameToValidate) {
      return {
        title: 'Name required',
        description: 'Please input a name for your new logger.',
        color: 'info',
      };
    } else if (nameToValidate.length > characterLimit) {
      return {
        title: 'Name too large',
        description: 'Please input a smaller name for your new logger.',
        color: 'info',
      };
    }

    return null;
  };

  const validateDescription = (descriptionToValidate) => {
    if (descriptionToValidate.length > characterLimit) {
      return {
        title: 'Description too large',
        description: 'Please input a smaller description for your new logger.',
        color: 'info',
      };
    }

    return null;
  };

  const handleCrawlerLabelFilterChange = (e) => {
    const { value } = e.target;
    setCrawlerLabelFilter(value);
  };

  const handleRegexLabelFilterChange = (e) => {
    const { value } = e.target;
    setRegexLabelFilter(value);
  };

  const onSubmit = (e) => {
    e.preventDefault();
    setIsUpdateLoading(true);

    // TODO: there is probably a better way to do this
    const toastErrors = [
      validateName(name),
      validateDescription(description),
    ].filter((obj) => obj !== null);
    if (toastErrors.length !== 0) {
      setIsUpdateLoading(false);
    }
    setIsUpdateLoading(false);

    api.updateLogger(
      currentLogger,
      {
        name,
        description,
        crawlerPartialLabelId: crawlerLabel.id ?? null,
        regexLabelId: regexLabel.id ?? null,
      },
      (res) => {
        const { status } = res.data;
        if (status === 200) {
          dispatch(setUpdateLoggers(true));
          dispatch(
            addNotification({
              title: 'Logger updated successfully',
              type: 'success',
            })
          );
        } else {
          dispatch(
            addNotification({
              title: 'Could not update logger',
              type: 'danger',
            })
          );
        }
        setIsUpdateLoading(false);
      }
    );
  };

  const onDelete = (e) => {
    e.preventDefault();
    setIsDeleteDrawerLoading(true);

    api.deleteLogger(currentLogger, (res) => {
      const { status } = res.data;
      if (status === 200) {
        dispatch(setUpdateLoggers(true));
        dispatch(
          addNotification({
            title: 'Logger deleted successfully',
            type: 'success',
          })
        );
        // redirect to MyLoggers
        history.replace(routes.myLoggers.ref());
        setIsDeleteDrawerOpen(false);
      } else {
        dispatch(
          addNotification({
            title: 'Could not delete logger',
            type: 'danger',
          })
        );
        setIsDeleteDrawerError(true);
      }
      setIsDeleteDrawerLoading(false);
    });
  };

  return (
    <Layout title={'Settings'} loading={isLoading} isError={isError}>
      <div className="columns mb-2 is-multiline">
        <div className="column is-one-third-widescreen is-full-tablet">
          <h1 className="title mb-1">General Information</h1>
          <p className="has-text-weight-light">
            In this section you can change your logger&apos;s information.
          </p>
        </div>
        <div className="column">
          <div className="field">
            <label className="label">Name</label>
            <div className="control">
              <input
                className="input"
                name="name"
                type="text"
                value={name}
                onChange={handleNameChange}
              />
            </div>
            <p className="help">
              Only letters, numbers and whitespaces allowed
            </p>
          </div>
          <div className="field">
            <label className="label">Description</label>
            <div className="control">
              <textarea
                className="textarea"
                name="description"
                placeholder="Logger Description"
                value={description}
                onChange={handleDescriptionChange}
              />
              <p
                className={cx('help', {
                  'has-text-warning': description.length > characterLimit - 20,
                  'has-text-danger': description.length > characterLimit,
                })}
              >
                {`${description.length}/${characterLimit}`}
                {description.length > characterLimit - 20 ? (
                  <Icon
                    size="small"
                    color={
                      description.length > characterLimit ? 'danger' : 'warning'
                    }
                    icon="fa-exclamation-triangle"
                  />
                ) : (
                  ''
                )}
              </p>
            </div>
          </div>
        </div>
      </div>
      <Divider />
      <div className="columns mt-2 is-multiline">
        <div className="column is-one-third-widescreen is-full-tablet">
          <h1 className="title mb-1">General Behaviour</h1>
          <p className="has-text-weight-light">
            In this section you can customize the behaviour of the detection
            module
          </p>
        </div>
        <div className="column">
          <div className="field">
            <label className="label">Crawler Partial Match Label</label>
            <Label
              deletable={crawlerLabel.name ? true : false}
              onDelete={() => setCrawlerLabel({})}
              color={crawlerLabel.color ? crawlerLabel.color : 'grey'}
            >
              {crawlerLabel.name ? crawlerLabel.name : 'No Label'}
            </Label>
            <DropDown className="">
              <div className="field px-2">
                <p className="control has-icons-left">
                  <input
                    className="input is-small"
                    type="text"
                    placeholder="Search labels"
                    value={crawlerLabelFilter}
                    onChange={handleCrawlerLabelFilterChange}
                  />
                  <span className="icon is-small is-left">
                    <i className="fas fa-search"></i>
                  </span>
                </p>
              </div>
              {getCrawlersLabelOptions()}
            </DropDown>
            <p className="help">
              Label to be applied when there is a mismatch between user agent
              and domain
            </p>
          </div>
          <div className="field">
            <label className="label">ReGex Label</label>
            <Label
              deletable={regexLabel.name ? true : false}
              onDelete={() => setRegexLabel({})}
              color={regexLabel.color ? regexLabel.color : 'grey'}
            >
              {regexLabel.name ? regexLabel.name : 'No Label'}
            </Label>
            <DropDown className="">
              <div className="field px-2">
                <p className="control has-icons-left">
                  <input
                    className="input is-small"
                    type="text"
                    placeholder="Search labels"
                    value={regexLabelFilter}
                    onChange={handleRegexLabelFilterChange}
                  />
                  <span className="icon is-small is-left">
                    <i className="fas fa-search"></i>
                  </span>
                </p>
              </div>
              {getRegexLabelOptions()}
            </DropDown>
            <p className="help">
              Label to be applied when a match is found on the user agent but it
              is not on the crawlers list
            </p>
          </div>
          <Button
            className="is-pulled-right"
            onClick={onSubmit}
            loading={isUpdateLoading}
          >
            Save Changes
          </Button>
        </div>
      </div>

      <Divider />
      <div className="columns mt-2 is-multiline">
        <div className="column is-one-third-widescreen is-full-tablet">
          <h1 className="title mb-1">Danger Zone</h1>
          <p className="has-text-weight-light">
            Here you can delete your logger if you don&apos;t want to use it
            anymore. Please keep in mind that this actions is not reversible and
            all data will be lost.
          </p>
        </div>
        <div className="column">
          <Button
            className="is-pulled-right"
            type="text"
            onClick={() => setIsDeleteDrawerOpen(true)}
          >
            Delete Logger
          </Button>
        </div>
      </div>
      <Drawer
        isError={isDeleteDrawerError}
        isLoading={isDeleteDrawerLoading}
        isOpen={isDeleteDrawerOpen}
        onClose={() => setIsDeleteDrawerOpen(false)}
      >
        <p className="has-text-weight-bold is-size-4 mb-2">
          Are you sure you want to delete this Logger?
        </p>
        <p className="has-text-weight-light">
          This action is irreversible and you will never be able to use this
          logger or its data again.
        </p>
        <Button className="is-pulled-right" type="text" onClick={onDelete}>
          Delete Logger
        </Button>
      </Drawer>
    </Layout>
  );
};

export default Settings;
