import { React } from 'react';
import PropTypes from 'prop-types';

import './style.scss';

const Filter = ({ column, table }) => {
  const firstValue = table.getPreFilteredRowModel().flatRows[0]?.getValue(column.id);
  const columnFilterValue = column.getFilterValue();

  const handleInputChangeMin = (e) => {
    e.persist();
    column.setFilterValue(old => [e.target.value, old ? old[1] : null]);
  };

  const handleInputChangeMax = (e) => {
    e.persist();
    column.setFilterValue(old => [old ? old[0] : null, e.target.value]);
  };

  return typeof firstValue === 'number' ? (
    <div className="is-flex is-flex-direction-row" onClick={e => e.stopPropagation()}>
      <input
        type="number"
        value={(columnFilterValue ? columnFilterValue[0] : '') ?? ''}
        onChange={handleInputChangeMin}
        placeholder={'Min'}
        className="Small input"
      />
      <input
        type="number"
        value={(columnFilterValue ? columnFilterValue[1] : '') ?? ''}
        onChange={handleInputChangeMax}
        placeholder={'Max'}
        className="Small input"
      />
    </div>
  ) : (
    <input
      className="Small input"
      onChange={e => column.setFilterValue(e.target.value)}
      onClick={e => e.stopPropagation()}
      placeholder={'Search...'}
      type="text"
      value={(columnFilterValue ?? '')}
    />
  );
};

Filter.propTypes = {
  table: PropTypes.any,
  column: PropTypes.any,
};

export default Filter;
