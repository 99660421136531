import React from 'react';
import PropTypes from 'prop-types';

import Card from 'components/common/Card';
import DoughnutChart from 'components/common/DoughnutChart';
import ChartLabel from 'components/common/ChartLabel';

const GroupsCard = ({ groups = [] }) => {

  const chartBackgroundColor = [
    '#7c98ab',
    '#9c9359',
    '#dbbc8e',
    '#ae8a8c',
    '#8c7386',
    '#d08d8b',
    '#766161',
    '#a9c8c0',
    '#a3cb8f',
  ];

  const getCustomLabels = () => (
    groups.map(({ name }, index) => (
      <div
        className="column is-one-third-desktop is-half-touch is-flex is-align-items-center my-1"
        key={name}
      >
        <ChartLabel
          label={name}
          color={chartBackgroundColor[index]}
        />
      </div>
    ))
  );

  return (
    <Card>
      <h3 className="has-text-weight-bold has-text-light mb-2">
        Groups
      </h3>
      <div className="columns is-multiline">
        <div
          className="column is-12 columns is-variable is-1 is-multiline"
          style={{ width: '100%', minHeight: 192 }}
        >
          {getCustomLabels()}
        </div>
        <div className="column is-12">
          <DoughnutChart
            id="groups-chart"
            data={groups.map(group => group.count)}
            labels={groups.map(group => group.name)}
            backgroundColor={chartBackgroundColor}
            circumference={180}
            rotation={270}
            height={200}
          />
        </div>
      </div>
    </Card>
  );
};

GroupsCard.propTypes = {
  groups: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      count: PropTypes.number
    })
  ),
};

export default GroupsCard;
