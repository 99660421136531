import React, { useState, useEffect, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import Layout from 'components/Layout/Layout';
import KeyCard from 'components/Keys/KeyCard';
import api from 'services/api';
import Drawer from 'components/common/Drawer';
import NewKeyForm from 'components/Keys/NewKeyForm';
import { addNotification } from 'actions/globalActions';
import Button from 'components/common/Button';

const Keys = () => {

  const dispatch = useDispatch();

  const [keys, setKeys] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [isError, setIsError] = useState(false);

  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [isDrawerLoading, setIsDrawerLoading] = useState(false);
  const [isDrawerError, setIsDrawerError] = useState(false);

  const deleteKey = (deletedKeyId) => {
    setKeys(keys.filter(key => key.id !== deletedKeyId));
  };

  const renameKey = (renamedKey) => {
    setKeys(keys.map(key => (
      key.id === renamedKey.id ? renamedKey : key
    )));
  };

  const currentLogger = useSelector(state => state.global.currentLogger);

  const fetchKeys = useCallback(() => {
    setIsLoading(true);
    setIsError(false);
    api.getKeys(currentLogger, (res) => {
      setIsLoading(false);
      const { status, keys } = res.data;
      if (status === 200) {
        setKeys(keys);
      } else {
        setIsError(true);
      }
      setIsLoading(false);
    });
  }, [currentLogger]);

  const createNewKey = (data) => {
    setIsDrawerLoading(true);
    api.createKey(currentLogger, data, res => {
      const { status, key } = res.data;
      if (status === 200) {
        setKeys([...keys, key]);
        dispatch(addNotification({
          title: 'Key created successfully',
          type: 'success',
        }));
        setIsDrawerOpen(false);
      } else {
        setIsDrawerError(true);
      }
      setIsDrawerLoading(false);
    });
  };

  useEffect(() => {
    if (keys.length === 0 && isLoading) {
      fetchKeys();
    }
  }, [isLoading, keys, fetchKeys]);

  const drawKeyCards = () => (
    keys.map(apiKey => (
      <div
        key={apiKey.id}
        className="column is-one-quarter-fullhd is-one-third-widescreen is-half-desktop is-full-tablet"
      >
        <KeyCard
          {...apiKey}
          deleteKeyCallback={deleteKey}
          renameKeyCallback={renameKey}
        />
      </div>
    ))
  );


  return (
    <Layout title={'Keys'} loading={isLoading} error={isError}>
      <Drawer isError={isDrawerError} isLoading={isDrawerLoading} isOpen={isDrawerOpen} onClose={() => setIsDrawerOpen(false)}>
        <NewKeyForm onSubmitForm={createNewKey} />
      </Drawer>
      <div className="columns">
        <div className="column">
          <h1 className="title mb-1">Keys</h1>
          <p className="has-text-weight-light">
            You can create, edit and delete api keys. API keys are used to identify
            your logger in order for orwell to store and process your information.
          </p>
        </div>
        <div className="column is-narrow">
          <Button onClick={() => setIsDrawerOpen(true)}>
            New API Key
          </Button>
        </div>
      </div>
      <div className="columns is-multiline">
        {drawKeyCards()}
      </div>
    </Layout>
  );
};


export default Keys;
