import React, { useState } from 'react';
import PropTypes from 'prop-types';

import Button from 'components/common/Button';
import Logo from 'components/common/Logo';

import './styles.scss';
import api from 'services/api';
import { useDispatch } from 'react-redux';
import { logoutUser } from 'actions/userActions';

const Navbar = ({ showLogo }) => {
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();


  const getLogo = () => {
    if (showLogo) {
      return (
        <Logo />
      );
    }
  };

  const logout = () => {
    setLoading(true);
    api.logout((res) => {
      setLoading(false);
      if (res.data.status === 200) {
        dispatch(logoutUser());
      } else {
        console.log('oops');
      }
    },
    (error) => {
      console.log(`login error: ${error}`);
    });

  };

  return (
    <nav className="navbar is-flex is-align-items-center is-justify-content-space-between">
      <div>
        {getLogo()}
      </div>
      <div className="is-flex">
        <Button onClick={logout} color={'primary'} type="text" loading={loading}>
          Logout
        </Button>
      </div>
    </nav>
  );
};

Navbar.propTypes = {
  showLogo: PropTypes.bool,
};

export default Navbar;
