import React from 'react';
import PropTypes from 'prop-types';

import LoggerBtn from 'components/common/LoggerBtn';

import './styles.scss';
import { Link } from 'react-router-dom';

const LoggerCard = ({ loggerId, loggerName, loggerNotifications, loggerDescription }) => {

  const loggerPage = localStorage.getItem(`last-${loggerId}`) ? localStorage.getItem(`last-${loggerId}`) : `/${loggerId}/activity`;

  return (
    <div className="card logger-card">
      <header className="card-header">
        <p className="card-header-title">
          {loggerName}
        </p>
      </header>
      <div className="card-content is-flex is-flex-direction-column is-align-items-center has-text-centered">
        <Link to={loggerPage}>
          <LoggerBtn
            large
            loggerId={loggerId}
            loggerName={loggerName}
            loggerNotifications={loggerNotifications}
          />
        </Link>
        <p>
          {loggerDescription}
        </p>
      </div>
    </div>
  );
};

LoggerCard.propTypes = {
  loggerId: PropTypes.string,
  loggerName: PropTypes.string,
  loggerDescription: PropTypes.string,
  loggerNotifications: PropTypes.number
};

export default LoggerCard;
