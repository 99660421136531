import Layout from 'components/Layout/Layout';
import SessionsTable from 'components/LiveSessions/SessionsTable';
import { React, useCallback, useEffect, useState } from 'react';

import {
  createColumnHelper,
} from '@tanstack/react-table';
import api from 'services/api';
import { useSelector } from 'react-redux';
import Button from 'components/common/Button';
import { formatDate } from 'services/valueFormatter';

const columnHelper = createColumnHelper();

const columns = [
  columnHelper.accessor('ipAddress', {
    header: () => 'IP',
    cell: info => info.renderValue(),
  }),
  columnHelper.accessor('requesterId', {
    show: false,
  }),
  columnHelper.accessor('loggerId', {}),
  columnHelper.accessor('duration', {
    header: () => 'Duration',
  }),
  columnHelper.accessor('username', {
    header: () => <span>Username</span>,
  }),
  columnHelper.accessor('numberRequests', {
    header: () => <span>Number of Requests made</span>,
    cell: info => info.renderValue(),
  }),
  columnHelper.accessor('avgInterval', {
    header: 'Average Interval',
  }),
  columnHelper.accessor('stdInterval', {
    header: 'Standard Deviation',
  }),
  columnHelper.accessor('percentageRequestReferer', {
    header: 'Percentage of requests with referer',
  }),
  columnHelper.accessor('lastRequest', {
    header: 'Last Request made',
    cell: info => formatDate(info.getValue())
  }),
  columnHelper.accessor('numberSessionIds', {
    header: 'Number of diferent session ID',
  }),
  columnHelper.accessor('pageEntropy', {
    header: 'Entropy of requested pages',
  }),
  columnHelper.accessor('classification', {
    header: 'Classification',
  }),
  columnHelper.accessor('confidence', {
    header: 'Confidences',
  }),
  columnHelper.accessor('labelName', {
    header: 'label'
  }),

];


const LiveSessions = () => {
  const currentLogger = useSelector(state => state.global.currentLogger);

  const [data, setData] = useState(() => []);
  const [isLoading, setIsLoading] = useState(true);
  const [isError, setIsError] = useState(false);

  const fetchData = useCallback(() => {
    setIsLoading(true);
    setIsError(false);

    api.getliveSessions(currentLogger, res => {
      if (res.data.status === 200) {
        setData([...res.data.sessions]);
        setIsLoading(false);
      } else {
        setIsError(true);
        console.error('Error fetching data:', res.data.errorMsg);
      }
    });

  }, [currentLogger]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  return (
    <Layout title={'Live'} loading={isLoading} error={isError}>
      <Button onClick={() => fetchData()}>
        Update
      </Button>
      <SessionsTable
        {...{
          data,
          columns,
        }}
      />
    </Layout>
  );
};

export default LiveSessions;