import React from 'react';
import { BrowserRouter, Switch, Route, Redirect } from 'react-router-dom';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';

import routes from 'routes';

import RouteHandler from 'handlers/RouteHandler';
import DataHandler from 'handlers/DataHandler';

import LoggerLanding from 'pages/LoggerLanding';
import Activity from 'pages/Activity';
import Requesters from 'pages/Requesters';
import RequestersRequests from 'pages/Requester';
import Performance from 'pages/Performance';
import Bots from 'pages/Bots';
import Whitelist from 'pages/Whitelist';
import Blacklist from 'pages/Blacklist';
import Logs from 'pages/Logs';
import NotFound from 'pages/NotFound';
import Login from 'pages/Login';
import Keys from 'pages/Keys';
import MyLoggers from 'pages/MyLoggers';
import CreateLogger from 'pages/CreateLogger';
import Groups from 'pages/Groups';
import Crawlers from 'pages/Crawlers';
import Stopwords from 'pages/Stopwords';
import Labels from 'pages/Labels';
import Notifications from 'components/Layout/Notifications';
import Settings from 'pages/Settings';
import LiveSessions from 'pages/LiveSessions';
import SuspiciousLogs from 'pages/SuspiciousLogs';
import TopDailyRequesters from 'pages/TopDailyRequesters';

const PrivateRoute = ({ children, ...rest }) => {

  const loggedIn = useSelector(state => state.user.loggedIn);

  return (
    <Route {...rest} render={() => {
      return loggedIn
        ? children
        : <Redirect to="/login" />;
    }}
    />
  );
};

PrivateRoute.propTypes = {
  children: PropTypes.any,
};


const Router = () => {

  const loggedIn = useSelector(state => state.user.loggedIn);

  return (
    <BrowserRouter>
      <Switch>
        {/* General Routes */}
        <Route exact path={routes.login.def}>
          {loggedIn ? <Redirect to="/" /> : <Login />}
        </Route>
        <PrivateRoute exact path={routes.myLoggers.def}>
          <MyLoggers />
        </PrivateRoute>
        <PrivateRoute exact path={routes.createLogger.def}>
          <CreateLogger />
        </PrivateRoute>

        <PrivateRoute exact path={routes.loggerLanding.def}>
          <LoggerLanding />
        </PrivateRoute>
        {/* Dashboard Routes */}
        <PrivateRoute exact path={routes.activity.def}>
          <Activity />
        </PrivateRoute>
        <PrivateRoute exact path={routes.performance.def}>
          <Performance />
        </PrivateRoute>
        <PrivateRoute exact path={routes.liveSessions.def}>
          <LiveSessions />
        </PrivateRoute>
        {/* Dashboard General Routes */}
        <PrivateRoute exact path={routes.requesters.def}>
          <Requesters />
        </PrivateRoute>
        <PrivateRoute exact path={routes.topDailyRequesters.def}>
          <TopDailyRequesters />
        </PrivateRoute>
        <PrivateRoute exact path={routes.suspiciousLogs.def}>
          <SuspiciousLogs />
        </PrivateRoute>
        <PrivateRoute exact path={routes.bots.def}>
          <Bots />
        </PrivateRoute>
        <PrivateRoute exact path={routes.blacklist.def}>
          <Blacklist />
        </PrivateRoute>
        <PrivateRoute exact path={routes.whitelist.def}>
          <Whitelist />
        </PrivateRoute>
        <PrivateRoute exact path={routes.notifications.def}>
          <Activity />
        </PrivateRoute>
        {/* Dashboard Options Routes */}
        <PrivateRoute exact path={routes.logs.def}>
          <Logs />
        </PrivateRoute>
        <PrivateRoute exact path={routes.rules.def}>
          <Activity />
        </PrivateRoute>
        <PrivateRoute exact path={routes.crawlers.def}>
          <Crawlers />
        </PrivateRoute>
        <PrivateRoute exact path={routes.stopwords.def}>
          <Stopwords />
        </PrivateRoute>
        <PrivateRoute exact path={routes.keys.def}>
          <Keys />
        </PrivateRoute>
        <PrivateRoute exact path={routes.groups.def}>
          <Groups />
        </PrivateRoute>
        <PrivateRoute exact path={routes.labels.def}>
          <Labels />
        </PrivateRoute>
        <PrivateRoute exact path={routes.settings.def}>
          <Settings />
        </PrivateRoute>

        <PrivateRoute exact path={routes.requestersRequests.def}>
          <RequestersRequests />
        </PrivateRoute>

        <Route>
          <NotFound />
        </Route>
      </Switch>

      {/* Global Notifications */}
      <Notifications />

      {/* Handlers */}
      <RouteHandler />
      <DataHandler />
    </BrowserRouter>
  );
};

export default Router;
