import React, { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import Layout from 'components/Layout/Layout';
import Table from 'components/common/Table';
import api from 'services/api';

const Blacklist = () => {
  const currentLogger = useSelector(state => state.global.currentLogger);

  const [data, setData] = useState({});
  const [isLoading, setIsLoading] = useState(true);
  const [isError, setIsError] = useState(false);

  const [curPage, setCurPage] = useState(1);
  const [nRequests] = useState(20);

  const formatTimestamp = (timestamp) => {
    if (!timestamp) {
      return;
    }

    return new Date(timestamp).toLocaleString();
  };

  const parseData = (data) => {
    if (!data.blackListers) {
      return;
    }
    const blackListers = data.blackListers;
    const dataParsed = [];
    for (let i = 0; i < blackListers.length; i++) {
      dataParsed.push([
        i, { ipAddress: blackListers[i].ipAddress, loggerId: currentLogger, requesterId: blackListers[i].id }, blackListers[i].avgTimeTaken, blackListers[i].totalRequests,
        formatTimestamp(blackListers[i].firstAccess), formatTimestamp(blackListers[i].lastAccess), { name: blackListers[i].name, color: blackListers[i].colorLabel }
      ]);
    }
    return dataParsed;
  };

  /**
   * Fetches for log data.
   */
  const fetchBots = useCallback(() => {
    setIsLoading(true);
    setIsError(false);
    api.getBlacklist(currentLogger, nRequests, curPage, res => {
      setIsLoading(false);
      if (res.data.status === 200) {
        setData(res.data);
      } else {
        setIsError(true);
      }
    });
  }, [curPage, currentLogger, nRequests]);

  /**
   * Calls fetchLogs onload.
   */
  useEffect(() => {
    if (isLoading) {
      fetchBots();
    }
  }, [isLoading, fetchBots]);

  return (
    <Layout title={'Bots'} loading={isLoading} error={isError}>
      {isError}
      <Table
        headerAttributes={[
          'IP Address', 'Avg Time per Request (ms)', 'Total Requests',
          'First Access', 'Last Access', 'Label'
        ]}
        data={parseData(data)}
        drawGroupPosition={6}
        drawIpAddressPosition={1}
        curPage={curPage}
        nPages={data.numPages}
        callBackUrl={setCurPage}
        setLoading={setIsLoading}
      />
    </Layout>
  );
};

export default Blacklist;
