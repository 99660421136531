import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

import './styles.scss';

const Icon = ({ icon, size = 'normal', color = 'dark' }) => (
  <span
    className={cx('icon', `has-text-${color}`, {
      'is-small': size === 'small',
      'is-medium': size === 'medium',
      'is-large': size === 'large',
      'is-larger': size === 'larger',
      'is-huge': size === 'huge',
    })}
  >
    <i
      className={cx('fas', `${icon}`, {
        '': size === 'small',
        'fa-lg': size === 'medium',
        'fa-2x': size === 'large',
        'fa-3x': size === 'larger',
        'fa-4x': size === 'huge',
      })}
    >
    </i>
  </span>
);

Icon.propTypes = {
  icon: PropTypes.string,
  size: PropTypes.oneOf(['small', 'normal', 'medium', 'large', 'larger', 'huge']),
  color: PropTypes.string
};

export default Icon;
