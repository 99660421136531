import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';

import { addNotification } from 'actions/globalActions';
import DropDown from '../Dropdown';
import Label from '../Label';
import api from 'services/api';

const EditableLabel = ({
  labelName,
  labelColor,
  requesterId,
  setLoading,
  labels = [],
}) => {
  const currentLogger = useSelector((state) => state.global.currentLogger);

  const [label, setLabel] = useState({
    name: labelName,
    color: labelColor,
  });

  const [labelFilter, setLabelFilter] = useState('');
  const dispatch = useDispatch();

  const handleLabelFilterChange = (e) => {
    const { value } = e.target;
    setLabelFilter(value);
  };

  const updateLabel = (labelId) => {
    api.updateRequesterLabel(currentLogger, requesterId, { labelId }, (res) => {
      if (res.data.status === 200) {
        setLabel({
          ...res.data.label,
        });
        dispatch(
          addNotification({
            title: 'Requester label updated',
            type: 'success',
          })
        );
        setLoading(true);
      } else {
        dispatch(
          addNotification({
            title: 'Cannot update requester label',
            description: 'Please refresh the page and try again',
            type: 'warning',
          })
        );
      }
      setLoading(false);
    });
  };

  const deleteLabel = () => {
    api.deleteRequesterLabel(currentLogger, requesterId, (res) => {
      if (res.data.status === 200) {
        setLabel({
          ...res.data.label,
        });
        dispatch(
          addNotification({
            title: 'Requester label deleted',
            type: 'success',
          })
        );
        setLoading(true);
      } else {
        dispatch(
          addNotification({
            title: 'Cannot delete requester label',
            description: 'Please refresh the page and try again',
            type: 'warning',
          })
        );
      }
      setLoading(false);
    });
  };

  const getLabelOptions = () =>
    labels
      .filter(({ name }) =>
        name.toLowerCase().startsWith(labelFilter.toLowerCase())
      )
      .map(({ id, color, name }) => (
        <a
          href="#"
          className="dropdown-item has-text-left"
          key={id}
          onMouseDown={() => updateLabel(id)}
        >
          <Label color={color}>{name}</Label>
        </a>
      ));

  useEffect(() => {
    setLabel({
      name: labelName,
      color: labelColor,
    });
  }, [labelName, labelColor]);

  return (
    <div>
      <Label
        color={label.color ? label.color : 'grey'}
        deletable={label.name ? true : false}
        onDelete={deleteLabel}
      >
        {label.name ? label.name : 'No Label'}
      </Label>
      <DropDown className="is-pulled-right">
        <div className="field px-2">
          <p className="control has-icons-left">
            <input
              className="input is-small"
              type="text"
              placeholder="Search labels"
              value={labelFilter}
              onChange={handleLabelFilterChange}
            />
            <span className="icon is-small is-left">
              <i className="fas fa-search"></i>
            </span>
          </p>
        </div>
        {getLabelOptions()}
      </DropDown>
    </div>
  );
};

EditableLabel.propTypes = {
  labelName: PropTypes.string,
  labelColor: PropTypes.string,
  requesterId: PropTypes.string,
  setLoading: PropTypes.func,
  labels: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      name: PropTypes.string,
      color: PropTypes.string,
    })
  ),
};

export default EditableLabel;
