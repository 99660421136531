import React from 'react';
import PropTypes from 'prop-types';

import MetricCard from 'components/common/MetricCard';
import { truncateIntegerNumber, truncateTime } from 'services/valueFormatter';

const ActivityMetrics = ({ requests, processingTime, requesters, blacklist, whitelist, bots }) => {
  return (
    <>
      <div className="column is-one-third-widescreen is-half-desktop">
        <MetricCard
          icon="fa-paper-plane"
          title="Total Requests"
          value={truncateIntegerNumber(requests.value)}
          variation={requests.variation}
        />
      </div>
      <div className="column is-one-third-widescreen is-half-desktop">
        <MetricCard
          icon="fa-stopwatch"
          title="Average Processing Time"
          value={truncateTime(processingTime.value)}
          variation={processingTime.variation}
        />
      </div>
      <div className="column is-one-third-widescreen is-half-desktop">
        <MetricCard
          icon="fa-users"
          title="Unique Requesters"
          value={truncateIntegerNumber(requesters.value)}
          variation={requesters.variation}
        />
      </div>
      <div className="column is-one-third-widescreen is-half-desktop">
        <MetricCard
          icon="fa-ban"
          title="Blacklist Requests"
          value={truncateIntegerNumber(blacklist.value)}
          variation={blacklist.variation}
        />
      </div>
      <div className="column is-one-third-widescreen is-half-desktop">
        <MetricCard
          icon="fa-check-circle"
          title="Whitelist Requests"
          value={truncateIntegerNumber(whitelist.value)}
          variation={whitelist.variation}
        />
      </div>
      <div className="column is-one-third-widescreen is-half-desktop">
        <MetricCard
          icon="fa-robot"
          title="Bot Requests"
          value={truncateIntegerNumber(bots.value)}
          variation={bots.variation}
        />
      </div>
    </>
  );
};

ActivityMetrics.propTypes = {
  requests: PropTypes.shape({
    value: PropTypes.any,
    variation: PropTypes.number
  }),
  processingTime: PropTypes.shape({
    value: PropTypes.any,
    variation: PropTypes.number
  }),
  requesters: PropTypes.shape({
    value: PropTypes.any,
    variation: PropTypes.number
  }),
  blacklist: PropTypes.shape({
    value: PropTypes.any,
    variation: PropTypes.number
  }),
  whitelist: PropTypes.shape({
    value: PropTypes.any,
    variation: PropTypes.number
  }),
  bots: PropTypes.shape({
    value: PropTypes.any,
    variation: PropTypes.number
  }),
};

export default ActivityMetrics;
