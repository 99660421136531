import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

import './styles.scss';
import { formatDecimalNumber } from 'services/valueFormatter';

const Variation = ({ variation, className }) => (
  <span
    className={cx('p-2 variation', className, {
      'has-background-success-light has-text-success': variation > 0,
      'has-background-danger-light has-text-danger': variation < 0,
      'has-background-light-light has-text-light': !variation,
    })}
  >
    <span className="icon">
      <i className={cx('fas', {
        'fa-arrow-up': variation > 0,
        'fa-arrow-down': variation < 0,
        'fa-equals': !variation,
      })}
      >
      </i>
    </span>
    <span className="has-text-weight-bold is-pulled-right">
      {variation > 0 ? `+${formatDecimalNumber(variation)}` : formatDecimalNumber(variation)}
      %
    </span>
  </span>
);

Variation.propTypes = {
  variation: PropTypes.number,
  className: PropTypes.any,
};

export default Variation;
