import React from 'react';
import PropTypes from 'prop-types';

import Card from 'components/common/Card';
import LineChart from 'components/common/LineChart';

const RequestsChartCard = ({ requests, currentTime = 23 }) => {

  const sortedRequests = [
    ...requests.filter(elem => elem.label > currentTime),
    ...requests.filter(elem => elem.label <= currentTime)
  ];

  return (
    <Card>
      <h3 className="has-text-weight-bold has-text-light mb-2">
        Requests over time
      </h3>
      <LineChart
        id={'requests-chart-card'}
        labels={sortedRequests.map(request => request.label)}
        data={sortedRequests.map(request => request.value)}
        height={500}
      />
    </Card>
  );
};

RequestsChartCard.propTypes = {
  requests: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.number,
      label: PropTypes.any,
    })
  ),
  currentTime: PropTypes.number,
};

export default RequestsChartCard;
