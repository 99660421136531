
import React, { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import Layout from 'components/Layout/Layout';
import Table from 'components/common/Table';
import api from 'services/api';

const TopDailyRequesters = () => {
  const currentLogger = useSelector(state => state.global.currentLogger);

  const [data, setData] = useState({});
  const [isLoading, setIsLoading] = useState(true);
  const [isError, setIsError] = useState(false);

  const [curPage, setCurPage] = useState(1);
  const [nRequests] = useState(20);

  const formatTimestamp = (timestamp) => {
    if (!timestamp) {
      return;
    }

    return new Date(timestamp).toLocaleString();
  };

  const formatLocation = (location) => {
    return location ? location : 'N/A';
  };

  const parseData = (data) => {
    console.log('data:', data);
    if (!data.requesters) {
      return;
    }
    const { requesters, labels } = data;

    const dataParsed = [];

    for (let i = 0; i < requesters.length; i++) {
      dataParsed.push([
        i,
        { ipAddress: requesters[i].ipAddress, loggerId: currentLogger, requesterId: requesters[i].id },
        requesters[i].avgTimeTaken,
        requesters[i].totalRequests,
        formatTimestamp(requesters[i].firstAccess),
        formatTimestamp(requesters[i].lastAccess),
        formatLocation(requesters[i].location),
        { index: i, name: requesters[i].labelName, color: requesters[i].labelColor, labels, requesterId: requesters[i].id },
        requesters[i].groupName,
        // { index: i, logger: currentLogger, requesterId: requesters[i].id, groups }
      ]);
    }
    return dataParsed;
  };

  /**
   * Fetches for log data.
   */
  const fetchRequesters = useCallback(() => {
    setIsLoading(true);
    setIsError(false);
    api.getTopRequesters(currentLogger, nRequests, curPage, res => {
      console.log('data from api:', res.data);
      setIsLoading(false);
      if (res.data.status === 200) {
        setData(res.data);
      } else {
        setIsError(true);
      }
    });
  }, [curPage, currentLogger, nRequests]);

  /**
   * Calls fetchLogs onload.
   */
  useEffect(() => {
    if (isLoading) {
      fetchRequesters();
    }
  }, [isLoading, fetchRequesters]);

  return (
    <Layout title={'Requesters'} loading={isLoading} error={isError}>
      {isError}
      <Table
        headerAttributes={[
          'IP Address', 'Avg Time per Request (ms)', 'Total Requests',
          'First Access', 'Last Access', 'Location', 'Label', 'Group', ' '
        ]}
        data={parseData(data)}
        drawIpAddressPosition={1}
        drawLabelPosition={7}
        // drawAddRequesterToGroupPosition={7}
        curPage={curPage}
        nPages={data.numPages}
        callBackUrl={setCurPage}
        setLoading={setIsLoading}
      />
    </Layout>
  );
};

export default TopDailyRequesters;
