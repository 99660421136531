import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

import api from 'services/api';
import usePage from 'hooks/usePage';

import { loadUser } from 'actions/userActions';
import { setUpdateLoggers, addNotification } from 'actions/globalActions';

import Button from 'components/common/Button';
import Logo from 'components/common/Logo';

// theres probably a better way to do this

const Login = () => {
  usePage('Login');

  const dispatch = useDispatch();

  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    // if the user is on the login page then we should not make any requests to update loggers
    dispatch(setUpdateLoggers(false));
  });

  const validateEmail = (emailToValidate) => {
    // eslint-disable-next-line
    const emailRegex = /^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
    // TODO: there is probably a better way to do this
    if (!emailToValidate) {
      return {
        title: 'Email required',
        description: 'Please input an email address.',
        type: 'info',
      };
    } else if (!emailRegex.test(emailToValidate.toLowerCase())) {
      return {
        title: 'Invalid Email',
        description: 'Please input an actual email address.',
        type: 'info',
      };
    }

    return null;
  };

  const validatePassword = (passwordToValidate) => {
    // TODO: there is probably a better way to do this
    if (!passwordToValidate) {
      return {
        title: 'Password required',
        description: 'Please input a password address.',
        type: 'info',
      };
    }

    return null;
  };

  const onSubmit = (e) => {
    e.preventDefault();
    setLoading(true);

    // TODO: there is probably a better way to do this
    const toastErrors = [validateEmail(email), validatePassword(password)].filter(obj => obj !== null);
    if (toastErrors.length !== 0) {
      setLoading(false);
      dispatch(addNotification(toastErrors[0]));
      return;
    }

    api.login({ email, password },
      (res) => {
        setLoading(false);
        if (res.data.status === 200) {
          dispatch(loadUser(res.data.token));
          dispatch(setUpdateLoggers(true));
        } else {
          dispatch(addNotification({
            title: 'Wrong Credentials',
            description: 'Form data does not match our records.',
            type: 'danger',
          }));
        }
      },
      (err) => {
        console.log(`login error: ${err}`);
      }
    );
  };

  const handleEmailChange = (e) => {
    const { value } = e.target;
    setEmail(value);
  };

  const handlePasswordChange = (e) => {
    const { value } = e.target;
    setPassword(value);
  };

  return (
    <div className="hero is-fullheight is-dark" >
      <div className="hero-body container columns is-centered has-text-centered my-0">
        <div>
          <Logo dark large />
          <hr />
          <p className="subtitle">Big Brother is watching for you!</p>
          <div className="card mb-3">
            <div className="card-content">
              <form onSubmit={onSubmit}>
                <div className="field">
                  <div className="control">
                    <input className="input" name="email" placeholder="Email" onChange={handleEmailChange} />
                  </div>
                </div>
                <div className="field">
                  <div className="control">
                    <input className="input" name="password" type="password" placeholder="Password" onChange={handlePasswordChange} />
                  </div>
                </div>
                <Button fullwidth loading={loading}>Login</Button>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div >
  );
};

export default Login;
