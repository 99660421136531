const route = (def, ref) => ({
  def: def,
  ref: ref || (() => def),
});

export default {
  /* Login */
  login: route('/login'),

  myLoggers: route('/'),

  createLogger: route('/createlogger'),

  loggerLanding: route('/:loggerId'),

  /* Dashboard */
  activity: route(
    '/:loggerId/activity',
    (loggerId) => `/${loggerId}/activity`
  ),
  performance: route(
    '/:loggerId/performance',
    (loggerId) => `/${loggerId}/performance`
  ),
  liveSessions: route(
    '/:loggerId/live',
    (loggerId) => `/${loggerId}/live`
  ),

  /* General */
  requesters: route(
    '/:loggerId/requesters',
    (loggerId) => `/${loggerId}/requesters`
  ),
  suspiciousLogs: route(
    '/:loggerId/suspicious',
    (loggerId) => `/${loggerId}/suspicious`
  ),
  bots: route(
    '/:loggerId/bots',
    (loggerId) => `/${loggerId}/bots`
  ),
  blacklist: route(
    '/:loggerId/blacklist',
    (loggerId) => `/${loggerId}/blacklist`
  ),
  whitelist: route(
    '/:loggerId/whitelist',
    (loggerId) => `/${loggerId}/whitelist`
  ),
  notifications: route(
    '/:loggerId/notification',
    (loggerId) => `/${loggerId}/notification`
  ),

  /* Options */
  logs: route(
    '/:loggerId/logs',
    (loggerId) => `/${loggerId}/logs`
  ),
  rules: route(
    '/:loggerId/rules',
    (loggerId) => `/${loggerId}/rules`
  ),
  keys: route(
    '/:loggerId/keys',
    (loggerId) => `/${loggerId}/keys`
  ),
  settings: route(
    '/:loggerId/settings',
    (loggerId) => `/${loggerId}/settings`
  ),
  crawlers: route(
    '/:loggerId/crawlers',
    (loggerId) => `/${loggerId}/crawlers`
  ),
  stopwords: route(
    '/:loggerId/stopwords',
    (loggerId) => `/${loggerId}/stopwords`
  ),
  groups: route(
    '/:loggerId/groups',
    (loggerId) => `/${loggerId}/groups`
  ),
  labels: route(
    '/:loggerId/labels',
    (loggerId) => `/${loggerId}/labels`
  ),

  /* Requester specific */
  topDailyRequesters: route(
    '/:loggerId/requesters/top',
    (loggerId) => `/${loggerId}/requesters/top`
  ),
  requestersRequests: route(
    '/:loggerId/requesters/:requesterId',
    (loggerId, requesterId) => `/${loggerId}/requesters/${requesterId}`
  ),
};
