/**
 * Action constants
 */
const CHANGE_CURRENT_LOGGER = 'CHANGE_CURRENT_LOGGER';
const SET_LOGGERS = 'SET_LOGGERS';
const SET_UPDATE_LOGGERS = 'SET_UPDATE_LOGGERS';
const DEFAULT_ACTION = 'DEFAULT_ACTION';
const ADD_NOTIFICATION = 'ADD_NOTIFICATION';
const DELETE_NOTIFICATION = 'DELETE_NOTIFICATION';

/**
 * Action creators
 */
const changeCurrentLogger = (logger) => ({
  type: CHANGE_CURRENT_LOGGER,
  logger
});

const setLoggers = (loggers) => ({
  type: SET_LOGGERS,
  loggers
});

const setUpdateLoggers = (value) => ({
  type: SET_UPDATE_LOGGERS,
  value
});

const addNotification = (data) => ({
  type: ADD_NOTIFICATION,
  data: data
});

const deleteNotification = (data) => ({
  type: DELETE_NOTIFICATION,
  data: data
});

export {
  CHANGE_CURRENT_LOGGER,
  SET_LOGGERS,
  SET_UPDATE_LOGGERS,
  DEFAULT_ACTION,
  changeCurrentLogger,
  setLoggers,
  setUpdateLoggers,
  ADD_NOTIFICATION, addNotification,
  DELETE_NOTIFICATION, deleteNotification,
};
