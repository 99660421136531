import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Label from 'components/common/Label';
import Button from 'components/common/Button';
import api from 'services/api';
import { useDispatch, useSelector } from 'react-redux';
import { addNotification } from 'actions/globalActions';
import Drawer from 'components/common/Drawer';
import LabelsTableRowForm from '../LabelsTableRowForm';

const LabelsTableRow = ({ label = {}, labels = [], setLabels }) => {
  const currentLogger = useSelector(state => state.global.currentLogger);
  const dispatch = useDispatch();

  // edit drawer
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [isDrawerLoading, setIsDrawerLoading] = useState(false);
  const [isDrawerError, setIsDrawerError] = useState(false);

  // delete drawer
  const [isDeleteDrawerOpen, setIsDeleteDrawerOpen] = useState(false);
  const [isDeleteDrawerLoading, setIsDeleteDrawerLoading] = useState(false);
  const [isDeleteDrawerError, setIsDeleteDrawerError] = useState(false);

  const submitForm = (data) => {
    setIsDrawerLoading(true);
    api.updateLabel(currentLogger, label.id, data, res => {
      const { status, label: newLabel } = res.data;
      if (status === 200) {
        setLabels([
          ...labels.slice(0, labels.findIndex(e => e.id === label.id)),
          newLabel,
          ...labels.slice(labels.findIndex(e => e.id === label.id) + 1)
        ]);
        dispatch(addNotification({
          title: 'Label updated successfully',
          type: 'success',
        }));
        setIsDrawerOpen(false);
      } else {
        setIsDrawerError(true);
      }
      setIsDrawerLoading(false);
    });
  };

  const deleteLabel = () => {
    setIsDeleteDrawerLoading(true);
    api.deleteLabel(currentLogger, label.id, res => {
      setIsDeleteDrawerLoading(false);
      if (res.data.status === 200) {
        setLabels([
          ...labels.slice(0, labels.findIndex(e => e.id === label.id)),
          ...labels.slice(labels.findIndex(e => e.id === label.id) + 1)
        ]);
        dispatch(addNotification({
          title: 'Label deleted successfully',
          type: 'success',
        }));
      } else {
        setIsDeleteDrawerError(true);
        dispatch(addNotification({
          title: 'Cannot delete label',
          type: 'warning',
        }));
      }
    });
  };

  return (
    <tr>
      <td>
        <Label color={label.color}>
          {label.name}
        </Label>
      </td>
      <td>
        {label.name}
      </td>
      <td>
        {label.priority}
      </td>
      <td style={{ width: '100px' }}>
        {label.color}
      </td>
      <td style={{ width: '50px' }}>
        <Button size="small" noPadding type="text" onClick={() => setIsDrawerOpen(true)} className="is-pulled-right">
          Edit
        </Button>
        <Drawer isError={isDrawerError} isLoading={isDrawerLoading} isOpen={isDrawerOpen} onClose={() => setIsDrawerOpen(false)}>
          <LabelsTableRowForm
            label={label}
            priorities={labels.map(label => label.priority)}
            onSubmitForm={submitForm}
          />
        </Drawer>
      </td>
      <td style={{ width: '50px' }}>
        <button className="delete is-pulled-right" onClick={() => setIsDeleteDrawerOpen(true)} />
        <Drawer
          isError={isDeleteDrawerError}
          isLoading={isDeleteDrawerLoading}
          isOpen={isDeleteDrawerOpen}
          onClose={() => setIsDeleteDrawerOpen(false)}
        >
          <p className="has-text-weight-bold is-size-4 mb-2">Are you sure you want to delete this label?</p>
          <p className="has-text-weight-light">This action is irreversible and all labelled IPs will have a null label.</p>
          <Button className="is-pulled-right" type="text" onClick={deleteLabel}>
            Delete Label
          </Button>
        </Drawer>
      </td>
    </tr>
  );
};

LabelsTableRow.propTypes = {
  label: PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string,
    color: PropTypes.string
  }),
  labels: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      name: PropTypes.string,
      color: PropTypes.string
    })
  ),
  setLabels: PropTypes.func
};

export default LabelsTableRow;
