import React from 'react';
import cx from 'classnames';
import PropTypes from 'prop-types';

import './styles.scss';

const Button = ({
  color = 'primary',
  type = 'filled',
  size = 'normal',
  disabled = false,
  loading = false,
  fullwidth = false,
  noPadding = false,
  rounded = false,
  onClick,
  className = '',
  tooltipText = null,
  children
}) => {

  return (
    <button
      data-tooltip={tooltipText}
      onClick={onClick}
      className={cx('button', 'is-uppercase', className,
        `is-${size}`,
        type !== 'text' ? `is-${color}` : '',
        type === 'text' ? `has-text-${color}` : '', {
          'is-outlined': type === 'outlined',
          'is-text': type === 'text',
          'is-disabled': disabled,
          'is-loading': loading,
          'is-fullwidth': fullwidth,
          'p-0': noPadding,
          'is-rounded': rounded,
        }
      )}
      style={noPadding ? {
        height: '20px'
      } : {}}
    >
      {children}
    </button>
  );
};

Button.propTypes = {
  color: PropTypes.string,
  type: PropTypes.oneOf(['filled', 'outlined', 'text']),
  size: PropTypes.oneOf(['small', 'normal', 'medium', 'large']),
  disabled: PropTypes.bool,
  loading: PropTypes.bool,
  fullwidth: PropTypes.bool,
  rounded: PropTypes.bool,
  noPadding: PropTypes.bool,
  onClick: PropTypes.func,
  className: PropTypes.string,
  tooltipText: PropTypes.string,
  children: PropTypes.any,
};

export default Button;
