import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';

import { addNotification } from 'actions/globalActions';

import Label from 'components/common/Label';
import Button from 'components/common/Button';
import DropDown from 'components/common/Dropdown';

import './style.scss';

const NewStopwordForm = ({ onSubmitForm, labels = [] }) => {

  const dispatch = useDispatch();

  const [selectedLabel, setSelectedLabel] = useState({});
  const [selectedStopword, setSelectedStopword] = useState('');

  const [safewords, setSafewords] = useState([]);
  const [newSafeword, setNewSafeword] = useState('');

  const [labelFilter, setLabelFilter] = useState('');

  const handleStopwordChange = (e) => {
    const { value } = e.target;
    setSelectedStopword(value);
  };

  const handleNewSafeword = (e) => {
    const {value} = e.target;
    setNewSafeword(value);
  };

  const validateSafeword = (word) => {
    if (word.length === 0) {
      return {
        type: 'warning',
        title: 'Invalid Safeword',
        description: 'Safeword cannot be empty'
      };
    }
    return null;
  };

  const handleAddSafeWorld = (e) => {
    e.preventDefault();
    const error = validateSafeword(newSafeword);
    if (error === null) {
      const newSafewords = [...safewords, newSafeword.trim()];
      setSafewords(newSafewords);
      setNewSafeword('');
    } else {
      dispatch(addNotification(error));
    }
  };

  const handleRemoveSafeword = (index) => {
    safewords.splice(index, 1);
    setSafewords([...safewords]);
  };

  const validateName = (name) => {
    if (name.length === 0) {
      return {
        type: 'warning',
        title: 'Invalid stopword',
        description: 'Stopword cannot be empty'
      };
    }
    return null;
  };

  const submitForm = () => {
    const validationErrors = [validateName(selectedStopword)].filter(e => e !== null);
    if (validationErrors.length === 0) {
      onSubmitForm({
        stopword: selectedStopword,
        safewords: safewords,
        labelId: selectedLabel.id,
      });
      // clear input
      setSelectedStopword('');
      setSelectedLabel({});
      setNewSafeword('');
    } else {
      dispatch(addNotification(validationErrors[0]));
    }
  };

  const getLabelOptions = () =>
    labels
      .filter(({ name }) => name.toLowerCase().startsWith(labelFilter.toLowerCase()))
      .map(({ id, color, name }) => (
        <a
          href="#"
          className="dropdown-item has-text-left"
          key={id}
          onMouseDown={() => setSelectedLabel({ id, color, name })}
        >
          <Label
            color={color}
          >
            {name}
          </Label>
        </a>
      ));


  const handleLabelFilterChange = (e) => {
    const { value } = e.target;
    setLabelFilter(value);
  };

  return (
    <div className="control">
      <div className="field">
        <label className="label">Stopword</label>
        <input
          className="input"
          name="stopword"
          type="text"
          value={selectedStopword}
          onChange={handleStopwordChange}
        />
        <p className="help">Input the exact string for matching</p>
      </div>

      <div className="field">
        <label className="label">Label</label>
        <Label
          deletable={selectedLabel.name ? true : false}
          onDelete={() => setSelectedLabel({})}
          color={selectedLabel.color ? selectedLabel.color : 'grey'}
        >
          {selectedLabel.name ? selectedLabel.name : 'No Label'}
        </Label>
        <DropDown className="is-pulled-right">
          <div className="field px-2">
            <p className="control has-icons-left">
              <input
                className="input is-small"
                type="text"
                placeholder="Search labels"
                value={labelFilter}
                onChange={handleLabelFilterChange}
              />
              <span className="icon is-small is-left">
                <i className="fas fa-search"></i>
              </span>
            </p>
          </div>
          {getLabelOptions()}
        </DropDown>
        <p className="help">Choose an existing label or leave blank</p>
      </div>

      <div className="field">
        <label className="label">Safeword</label>
        <form className="input-container" onSubmit={handleAddSafeWorld}>
          <input
            className="input-box"
            name="safeword"
            type="text"
            value={newSafeword}
            onChange={handleNewSafeword}
          />
          <Button className="input-button">Add Safeword</Button>
        </form>
        <div className="columns m-1">
          <div className="column">
            <ul>
              {safewords.map((item, index) => (
                <li key={index}>
                  {item}
                </li>
              ))}
            </ul>
          </div>
          <div className="column">
            <ul>
              {safewords.map((item, index) => (
                <li key={index}>
                  <Button size="small" type="outlined" rounded={true} onClick={() => handleRemoveSafeword(index)} >X</Button>
                </li>
              ))}
            </ul>
          </div>
        </div>
      </div>

      <Button className="is-pulled-right" type="text" onClick={submitForm}>
        Save Changes
      </Button>
    </div>
  );

};

NewStopwordForm.propTypes = {
  onSubmitForm: PropTypes.func,
  labels: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      name: PropTypes.string,
      color: PropTypes.string
    })
  )
};

export default NewStopwordForm;
