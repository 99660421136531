import React, { useState } from 'react';
import cx from 'classnames';

import { useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';

import Layout from 'components/Layout/Layout';
import Button from 'components/common/Button';

import { setUpdateLoggers } from 'actions/globalActions';

import api from 'services/api';
import routes from 'routes';

import './styles.scss';

const CreateLogger = () => {
  /*
  const [filename, setFilename] = useState('Choose file...');
  const handleFileChange = (e) => {
    const file = e.target.files[0];
    setFilename(file.name);
    // TODO: upload file
  };
  */
  const history = useHistory();
  const dispatch = useDispatch();

  const [loading, setLoading] = useState(false);
  const [name, setName] = useState('');
  const [description, setDescription] = useState('');
  const [toastList, setToastList] = useState([]);

  const characterLimit = 255;

  const handleNameChange = (e) => {
    const { value } = e.target;
    setName(value);
  };

  const handleDescriptionChange = (e) => {
    const { value } = e.target;
    setDescription(value);
  };

  // TODO: use redux for notification handling
  const validateName = (nameToValidate) => {
    if (!nameToValidate) {
      return {
        title: 'Name required',
        description: 'Please input a name for your new logger.',
        color: 'info',
      };
    } else if (nameToValidate.length > characterLimit) {
      return {
        title: 'Name too large',
        description: 'Please input a smaller name for your new logger.',
        color: 'info',
      };
    }

    return null;
  };

  const validateDescription = (descriptionToValidate) => {
    if (descriptionToValidate.length > characterLimit) {
      return {
        title: 'Description too large',
        description: 'Please input a smaller description for your new logger.',
        color: 'info',
      };
    }

    return null;
  };

  const onCancel = (e) => {
    e.preventDefault();
    history.goBack();
  };

  const onSubmit = (e) => {
    e.preventDefault();
    setLoading(true);

    // TODO: there is probably a better way to do this
    const toastErrors = [
      validateName(name),
      validateDescription(description)
    ].filter(obj => obj !== null);
    if (toastErrors.length !== 0) {
      setLoading(false);
      setToastList([...toastList, ...toastErrors]);
      return;
    }

    api.createLogger({ name, description },
      (res) => {
        setLoading(false);
        if (res.data.status === 200) {
          dispatch(setUpdateLoggers(true));
          history.replace(routes.activity.ref(res.data.logger.id));
        } else {
          setToastList([
            ...toastList,
            {
              title: 'Something wrong',
              description: 'Please try again.',
              color: 'danger',
            }
          ]);
        }
      },
      (err) => {
        console.log(`Create logger error: ${err}`);
      }
    );
  };

  return (
    <Layout title="Create Logger" noSidebar navbarLogo>
      <div className="create-logger container-wrapper">
        {/*
        <div className="columns is-mobile">
          <div className="logger-btn column is-narrow">
            <div className="wrap">
              <div className="btn">A</div>
            </div>
          </div>
          <div className="column is-align-self-center">
            <label className="label">Logger Image</label>
            <div className="field file has-name is-right is-fullwidth is-primary">
              <label className="file-label">
                <input
                  className="file-input"
                  type="file"
                  accept="pdf/*"
                  onChange={handleFileChange}
                />
                <span className="file-cta">
                  <span className="file-icon mr-0">
                    <i className="fas fa-upload"></i>
                  </span>
                </span>
                <span className="file-name">
                  {filename}
                </span>
              </label>
            </div>
          </div>
        </div>
        */}
        <form>
          <div className="field">
            <div className="control">
              <label className="label">Logger Name</label>
              <input
                onChange={handleNameChange}
                className="input"
                name="name"
                placeholder="Logger Name"
                type="text"
              />
            </div>
          </div>
          <div className="field">
            <div className="control">
              {description.length > characterLimit - 20 ? (
                <div className="is-flex is-justify-content-space-between">
                  <label className="label">Logger Description</label>
                  {description.length > characterLimit - 20 && (
                    <p
                      className={cx(
                        'character-counter is-align-self-flex-end',
                        description.length > characterLimit
                          ? 'has-text-danger'
                          : ''
                      )}
                    >
                      {description.length + ' / ' + characterLimit}
                    </p>
                  )}
                </div>
              ) : (
                <label className="label">Logger Description</label>
              )}
              <textarea
                onChange={handleDescriptionChange}
                className="textarea"
                name="description"
                placeholder="Logger Description"
              />
            </div>
          </div>
          <div className="is-flex is-justify-content-space-between">
            <Button type="outlined" onClick={onCancel}>
              Cancel
            </Button>
            <Button loading={loading} onClick={onSubmit}>
              submit
            </Button>
          </div>
        </form>
      </div>
    </Layout>
  );
};

export default CreateLogger;
