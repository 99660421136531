import React, { useEffect, useState, useCallback } from 'react';
import { useSelector } from 'react-redux';

import Layout from 'components/Layout/Layout';
import ActivityMetrics from 'components/Activity/ActivityMetrics';
import Button from 'components/common/Button';
import FlagsCard from 'components/Activity/FlagsCard';
import CompositeFlagsCard from 'components/Activity/CompositeFlagsCard';
import LabelsCard from 'components/Activity/LabelsCard';
import GroupsCard from 'components/Activity/GroupsCard';

import api from 'services/api';

import './styles.scss';
import RequestsChartCard from 'components/Activity/RequestsChartCard';
import IPAddressesCard from 'components/Activity/IPAddressesCard';
import OSChartCard from 'components/Activity/OSChartCard';
import BrowserChartCard from 'components/Activity/BrowserChartCard';
import HardwareChartCard from 'components/Activity/HardwareChartCard';
import MapChartCard from 'components/Activity/MapChartCard';
import CountryTableCard from 'components/Activity/CountryTableCard';

const filters = [
  { title: 'Last 30 minutes', value: '30', timeframe: 'minutes', currentTime: new Date().getMinutes() },
  { title: 'Last 24 hours', value: '24', timeframe: 'hours', currentTime: new Date().getHours() },
  { title: 'All time', value: '87600', timeframe: 'hours', currentTime: new Date().getHours() }
];

const Activity = () => {
  const currentLogger = useSelector((state) => state.global.currentLogger);

  // fetched Data
  const [metrics, setMetrics] = useState({});
  const [flags, setFlags] = useState({});
  const [compositeFlags, setCompositeFlags] = useState({});
  const [labels, setLabels] = useState([]);
  const [groups, setGroups] = useState([]);
  const [requests, setRequests] = useState([]);
  const [ipAddresses, setIpAddresses] = useState([]);
  const [operatingSystems, setOperatingSystems] = useState([]);
  const [browsers, setBrowsers] = useState([]);
  const [hardware, setHardware] = useState([]);
  const [countries, setCountries] = useState([]);

  // page state
  const [isLoading, setIsLoading] = useState(true);
  const [isError, setIsError] = useState(false);

  // timefilter
  const [activeFilter, setActiveFilter] = useState(1);

  /**
   * Fetches for activity data.
   */
  const fetchActivity = useCallback(() => {
    setIsLoading(true);
    api.getActivity(
      currentLogger,
      filters[activeFilter].value,
      filters[activeFilter].timeframe,
      (res) => {
        if (res.data.status === 200) {
          setMetrics(res.data.metrics);
          setFlags(res.data.flags);
          setCompositeFlags(res.data.compositeFlags);
          setLabels(res.data.labels);
          setGroups(res.data.groups);
          setRequests(res.data.requests);
          setIpAddresses(res.data.ipAddresses);
          setOperatingSystems(res.data.operatingSystems);
          setBrowsers(res.data.browsers);
          setHardware(res.data.hardware);
          setCountries(res.data.countries);
        } else {
          setIsError(true);
        }
        setIsLoading(false);
      });
  }, [currentLogger, activeFilter]);

  /**
   * Calls fetchActivity onload and when filter changes.
   */
  useEffect(() => {
    fetchActivity();
  }, [fetchActivity]);

  /**
   * Gets the filter buttons.
   */
  const getFilterButtons = () => {
    return filters.map((filter, index) => {

      // Updates the currently active filter
      const changeFilter = () => {
        if (activeFilter === index) {
          fetchActivity();
        } else {
          setActiveFilter(index);
        }
      };

      return (
        <Button
          key={index}
          rounded
          onClick={changeFilter}
          type={activeFilter === index ? 'filled' : 'outlined'}
          className="activity-filter-btn"
        >
          {filter.title}
        </Button>
      );
    });
  };

  return (
    <Layout title={'Activity'} loading={isLoading} error={isError}>
      <div className="activity-filter-row">
        {getFilterButtons()}
      </div>

      <div className="columns is-desktop is-multiline">
        <ActivityMetrics {...metrics} />
      </div>
      <div className="columns is-desktop is-multiline">
        <div className="column is-one-third-widescreen is-full-desktop">
          <FlagsCard {...flags} />
        </div>
        <div className="column is-two-thirds-widescreen is-full-desktop">
          <CompositeFlagsCard {...compositeFlags} />
        </div>
      </div>

      <div className="columns is-desktop is-multiline">
        <div className="column is-half-widescreen is-full-desktop">
          <LabelsCard labels={labels} />
        </div>
        <div className="column is-half-widescreen is-full-desktop">
          <GroupsCard groups={groups} />
        </div>
      </div>

      <div className="columns is-desktop is-multiline">
        <div className="column is-two-thirds-widescreen is-full-desktop">
          <RequestsChartCard requests={requests} currentTime={filters[activeFilter].currentTime} />
        </div>
        <div className="column is-one-third-widescreen is-full-desktop">
          <IPAddressesCard ipAddresses={ipAddresses} />
        </div>
      </div>

      <div className="columns is-desktop is-multiline">
        <div className="column is-one-third-widescreen is-full-desktop">
          <OSChartCard operatingSystems={operatingSystems} />
        </div>
        <div className="column is-one-third-widescreen is-full-desktop">
          <BrowserChartCard browsers={browsers} />
        </div>
        <div className="column is-one-third-widescreen is-full-desktop">
          <HardwareChartCard hardware={hardware} />
        </div>
      </div>

      <div className="columns is-desktop is-multiline">
        <div className="column is-two-thirds-widescreen is-full-desktop">
          <MapChartCard countries={countries} />
        </div>
        <div className="column is-one-third-widescreen is-full-desktop">
          <CountryTableCard countries={countries.slice(0, 20)} />
        </div>
      </div>
    </Layout>
  );
};

export default Activity;
