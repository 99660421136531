import React from 'react';
import PropTypes from 'prop-types';

import Card from 'components/common/Card';
import DoughnutChart from 'components/common/DoughnutChart';
import Variation from 'components/common/Variation';

const CompositeFlagsCard = ({
  bot = {},
  botWhitelist = {},
  botBlacklist = {},
  whitelist = {},
  blacklist = {},
  uncategorized = {},
}) => {

  const flags = [
    { name: 'Bot', color: '#e7bc91', ...bot }, // Bot -> Pastel Brown
    { name: 'Bot Whitelist', color: '#84a98c', ...botWhitelist }, // Bot Whitelist -> Pastel Light Green
    { name: 'Bot Blacklist', color: '#c06765', ...botBlacklist }, // Bot Blacklist -> Pastel Light Red
    { name: 'Whitelist', color: '#adc178', ...whitelist }, // Whitelist -> Pastel Dark Green
    { name: 'Blacklist', color: '#b23a48', ...blacklist }, // Blacklist -> Pastel Dark Red
    { name: 'Uncategorized', color: '#c6e2e9', ...uncategorized } // Uncategorized -> Pastel Dark
  ];

  const getChartCustomLabels = () => (
    flags.map(({ name, color, variation }) => (
      <div
        className="is-flex is-justify-content-space-between is-align-items-center my-1"
        key={name}
      >
        <div className="is-flex is-align-items-center">
          <span
            className="is-inline-block mr-3"
            style={{
              backgroundColor: color,
              width: '1.5rem',
              height: '1.5rem',
              borderRadius: '4px',
            }}
          />
          {name}
        </div>
        <Variation variation={variation} />
      </div>
    ))
  );

  return (
    <Card>
      <h3 className="has-text-weight-bold has-text-light mb-2">
        Composite Flags
      </h3>
      <div className="columns is-desktop">
        <div className="column is-narrow">
          <DoughnutChart
            id="composite-flags-chart"
            height={102.5 * 3}
            data={flags.map(flag => flag.value)}
            labels={flags.map(flag => flag.name)}
            backgroundColor={flags.map(flag => flag.color)}
          />
        </div>
        <div className="column pl-4 is-flex is-flex-direction-column is-justify-content-space-between">
          {getChartCustomLabels()}
        </div>
      </div>
    </Card>
  );
};

CompositeFlagsCard.propTypes = {
  bot: PropTypes.shape({
    value: PropTypes.number,
    variation: PropTypes.number,
  }),
  botWhitelist: PropTypes.shape({
    value: PropTypes.number,
    variation: PropTypes.number,
  }),
  botBlacklist: PropTypes.shape({
    value: PropTypes.number,
    variation: PropTypes.number,
  }),
  whitelist: PropTypes.shape({
    value: PropTypes.number,
    variation: PropTypes.number,
  }),
  blacklist: PropTypes.shape({
    value: PropTypes.number,
    variation: PropTypes.number,
  }),
  uncategorized: PropTypes.shape({
    value: PropTypes.number,
    variation: PropTypes.number,
  }),
};

export default CompositeFlagsCard;
