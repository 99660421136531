import React from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import cx from 'classnames';

import usePage from 'hooks/usePage';

import Sidebar from 'components/Layout/Sidebar';
import Navbar from 'components/Layout/Navbar';
import LayoutLoading from 'components/Layout/LayoutLoading';
import LayoutError from 'components/Layout/LayoutError';

import './styles.scss';

const Layout = ({ title, noSidebar, navbarLogo, loading, error, children }) => {
  usePage(title);
  const { currentLogger, loggers } = useSelector(state => state.global);
  const currentLoggerData = loggers.find(l => l.id === currentLogger);
  const loggerName = currentLoggerData ? currentLoggerData.name : null;

  const getSidebar = () => {
    if (noSidebar === true) {
      return null;
    } else {
      return (
        <Sidebar
          loggerName={loggerName}
        />
      );
    }
  };

  const getContent = () => {
    if (loading) {
      return <LayoutLoading />;
    } else if (error) {
      return <LayoutError />;
    } else {
      return children;
    }
  };


  return (
    <div className="layout">
      {getSidebar()}
      <div className="layout-content-wrap">
        <Navbar showLogo={navbarLogo} />
        <div className={cx('layout-content', {
          'with-sidebar': !noSidebar
        })}
        >
          {getContent()}
        </div>
      </div>
    </div>
  );
};

Layout.propTypes = {
  title: PropTypes.string,
  noSidebar: PropTypes.bool,
  navbarLogo: PropTypes.bool,
  loading: PropTypes.bool,
  error: PropTypes.bool,
  children: PropTypes.any
};

export default Layout;
