import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { truncateIntegerNumber } from 'services/valueFormatter';

import Card from 'components/common/Card';
import Button from 'components/common/Button';
import Icon from 'components/common/Icon';

const TopURITable = ({ topURIs = [] }) => {

  const [page, setPage] = useState(0); // page 0 or 1
  const perPage = 6;
  const maxPage = Math.ceil(topURIs.length / perPage) - 1;

  const getRequestRows = () => (
    topURIs.map(({ uri, requests, queries }, index) => {
      if (index >= page * perPage && index < (page + 1) * perPage) {
        return (
          <tr
            key={uri}
          >
            <td>
              <a className="has-text-link is-size-7 has-tooltip-top" href={uri} data-tooltip={uri} target="_blank" rel="noreferrer">
                {uri}
              </a>
              <br />
              <p className="is-size-7">
                <span className="has-text-weight-bold">
                  {truncateIntegerNumber(requests)}
                </span>
                &nbsp;requests,&nbsp;
                <span className="has-text-weight-bold">
                  {truncateIntegerNumber(queries)}
                </span>
                &nbsp;queries
              </p>
            </td>
          </tr>
        );
      }
    })
  );

  const drawPagination = () => {
    if (topURIs.length !== 0) {
      return (
        <div className="is-flex is-justify-content-center mb-2">
          <Button
            type="text"
            size="small"
            className="mr-2"
            onClick={() => page === 0 ? '' : setPage(page - 1)}
          >
            <Icon
              color={page === 0 ? 'grey' : 'primary'}
              icon="fa-chevron-left"
            />
          </Button>
          <Button
            type="text"
            size="small"
            className="ml-2"
            onClick={() => page === maxPage ? '' : setPage(page + 1)}
          >
            <Icon
              color={page === maxPage ? 'grey' : 'primary'}
              icon="fa-chevron-right"
            />
          </Button>
        </div>
      );
    }
  };

  return (
    <Card>
      <h3 className="has-text-weight-bold has-text-light">
        Top 20 Requested URIs
      </h3>
      <div
        style={{ height: 380 }}
        className="is-flex is-flex-direction-column is-justify-content-space-between"
      >
        <table className="table is-fullwidth is-narrow">
          <tbody>
            {getRequestRows()}
          </tbody>
        </table>
        {drawPagination()}
      </div>
    </Card>
  );
};

TopURITable.propTypes = {
  topURIs: PropTypes.arrayOf(
    PropTypes.shape({
      uri: PropTypes.string,
      requests: PropTypes.any,
      queries: PropTypes.any
    })
  ),
};

export default TopURITable;
