import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router';
import PropTypes from 'prop-types';

import api from 'services/api';

import { addNotification } from 'actions/globalActions';
import Label from 'components/common/Label';
import DropDown from 'components/common/Dropdown';
import Card from 'components/common/Card';
import Button from 'components/common/Button';
import Icon from 'components/common/Icon';

const ClassifierCard = ({ requester, labels, groups }) => {
  const currentLogger = useSelector((state) => state.global.currentLogger);
  const { requesterId: currentRequester } = useParams();

  const [requesterLabel, setRequesterLabel] = useState({
    id: requester.labelId,
    name: requester.labelName,
    color: requester.labelColor,
  });

  const [requesterGroup, setRequesterGroup] = useState({
    id: requester.groupId,
    name: requester.groupName,
  });

  const [overrideLabel, setOverrideLabel] = useState(requester.overrideLabel);
  const [overrideGroup, setOverrideGroup] = useState(requester.overrideGroup);

  const [requesterIsBot, setRequesterIsBot] = useState(requester.isBot);
  const [requesterIsWhitelist, setRequesterIsWhitelist] = useState(
    requester.isWhitelist
  );
  const [requesterIsBlacklist, setRequesterIsBlacklist] = useState(
    requester.isBlacklist
  );

  const [labelFilter, setLabelFilter] = useState('');
  const [groupFilter, setGroupFilter] = useState('');

  const [isLabelLoading, setIsLabelLoading] = useState(false);
  const [isGroupLoading, setIsGroupLoading] = useState(false);
  const [isBotLoading, setIsBotLoading] = useState(false);
  const [isWhitelistLoading, setIsWhitelistLoading] = useState(false);
  const [isBlacklistLoading, setIsBlacklistLoading] = useState(false);

  const dispatch = useDispatch();

  const updateLabel = (labelId) => {
    setIsLabelLoading(true);
    api.updateRequesterLabel(
      currentLogger,
      currentRequester,
      { labelId },
      (res) => {
        if (res.data.status === 200) {
          setRequesterLabel({
            ...res.data.label,
          });
          dispatch(
            addNotification({
              title: 'Requester label updated',
              type: 'success',
            })
          );
        } else {
          dispatch(
            addNotification({
              title: 'Cannot update requester label',
              description: 'Please refresh the page and try again',
              type: 'warning',
            })
          );
        }
        setIsLabelLoading(false);
      }
    );
  };

  const deleteLabel = () => {
    setIsLabelLoading(true);
    api.deleteRequesterLabel(currentLogger, currentRequester, (res) => {
      if (res.data.status === 200) {
        setRequesterLabel({
          ...res.data.label,
        });
        dispatch(
          addNotification({
            title: 'Requester label deleted',
            type: 'success',
          })
        );
      } else {
        dispatch(
          addNotification({
            title: 'Cannot delete requester label',
            description: 'Please refresh the page and try again',
            type: 'warning',
          })
        );
      }
      setIsLabelLoading(false);
    });
  };

  const updateGroup = (groupId) => {
    setIsGroupLoading(true);
    api.updateRequesterGroup(
      currentLogger,
      currentRequester,
      { groupId },
      (res) => {
        if (res.data.status === 200) {
          setRequesterGroup({
            ...res.data.group,
          });
          dispatch(
            addNotification({
              title: 'Requester group updated',
              type: 'success',
            })
          );
        } else {
          dispatch(
            addNotification({
              title: 'Cannot update requester group',
              description: 'Please refresh the page and try again',
              type: 'warning',
            })
          );
        }
        setIsGroupLoading(false);
      }
    );
  };

  const deleteGroup = () => {
    setIsGroupLoading(true);
    api.deleteRequesterGroup(currentLogger, currentRequester, (res) => {
      if (res.data.status === 200) {
        setRequesterGroup({
          ...res.data.group,
        });
        dispatch(
          addNotification({
            title: 'Requester group deleted',
            type: 'success',
          })
        );
      } else {
        dispatch(
          addNotification({
            title: 'Cannot delete requester group',
            description: 'Please refresh the page and try again',
            type: 'warning',
          })
        );
      }
      setIsGroupLoading(false);
    });
  };

  const handleOverrideLabelChange = () => {
    console.log('override label');

    api.updateRequesterOverride(
      currentLogger,
      currentRequester,
      {
        overrideLabel: !overrideLabel,
        overrideGroup: overrideGroup,
      },
      (res) => {
        if (res.data.status === 200) {
          const { overrideLabel, overrideGroup } = res.data;
          setOverrideLabel(overrideLabel);
          setOverrideGroup(overrideGroup);
          dispatch(
            addNotification({
              title: 'Override option applied',
              type: 'success'
            })
          );
        } else {
          dispatch(
            addNotification({
              title: 'Cannot udpate override',
              description: 'Please refresh the page and try again',
              type: 'warning',
            })
          );
        }
      }
    );
  };

  const handleOverrideGroupChange = () => {
    console.log('override group');

    api.updateRequesterOverride(
      currentLogger,
      currentRequester,
      {
        overrideLabel: overrideLabel,
        overrideGroup: !overrideGroup,
      },
      (res) => {
        if (res.data.status === 200) {
          const { overrideLabel, overrideGroup } = res.data;
          setOverrideLabel(overrideLabel);
          setOverrideGroup(overrideGroup);
          dispatch(
            addNotification({
              title: 'Override label applyed',
              type: 'success'
            })
          );
        } else {
          dispatch(
            addNotification({
              title: 'Cannot udpate override',
              description: 'Please refresh the page and try again',
              type: 'warning',
            })
          );
        }
      }
    );
  };

  const handleIsBotChange = () => {
    setIsBotLoading(true);
    api.updateRequesterFlags(
      currentLogger,
      currentRequester,
      {
        isBot: !requesterIsBot,
        isWhitelist: requesterIsWhitelist,
        isBlacklist: requesterIsBlacklist,
      },
      (res) => {
        if (res.data.status === 200) {
          const { isBot, isWhitelist, isBlacklist } = res.data;
          setRequesterIsBot(isBot);
          setRequesterIsWhitelist(isWhitelist);
          setRequesterIsBlacklist(isBlacklist);
          dispatch(
            addNotification({
              title: 'Requester flags updated',
              type: 'success',
            })
          );
        } else {
          dispatch(
            addNotification({
              title: 'Cannot update requester flags',
              description: 'Please refresh the page and try again',
              type: 'warning',
            })
          );
        }
        setIsBotLoading(false);
      }
    );
  };

  const handleIsWhitelistChange = () => {
    setIsWhitelistLoading(true);
    api.updateRequesterFlags(
      currentLogger,
      currentRequester,
      {
        isBot: requesterIsBot,
        isWhitelist: !requesterIsWhitelist,
        isBlacklist: requesterIsBlacklist,
      },
      (res) => {
        if (res.data.status === 200) {
          const { isBot, isWhitelist, isBlacklist } = res.data;
          setRequesterIsBot(isBot);
          setRequesterIsWhitelist(isWhitelist);
          setRequesterIsBlacklist(isBlacklist);
          dispatch(
            addNotification({
              title: 'Requester flags updated',
              type: 'success',
            })
          );
        } else {
          dispatch(
            addNotification({
              title: 'Cannot update requester flags',
              description: 'Please refresh the page and try again',
              type: 'warning',
            })
          );
        }
        setIsWhitelistLoading(false);
      }
    );
  };

  const handleIsBlacklistChange = () => {
    setIsBlacklistLoading(true);
    api.updateRequesterFlags(
      currentLogger,
      currentRequester,
      {
        isBot: requesterIsBot,
        isWhitelist: requesterIsWhitelist,
        isBlacklist: !requesterIsBlacklist,
      },
      (res) => {
        if (res.data.status === 200) {
          const { isBot, isWhitelist, isBlacklist } = res.data;
          setRequesterIsBot(isBot);
          setRequesterIsWhitelist(isWhitelist);
          setRequesterIsBlacklist(isBlacklist);
          dispatch(
            addNotification({
              title: 'Requester flags updated',
              type: 'success',
            })
          );
        } else {
          dispatch(
            addNotification({
              title: 'Cannot update requester flags',
              description: 'Please refresh the page and try again',
              type: 'warning',
            })
          );
        }
        setIsBlacklistLoading(false);
      }
    );
  };

  /**
   *
   * Creates label options for dropdown menu
   */
  const getLabelOptions = () =>
    labels
      .filter(({ name }) =>
        name.toLowerCase().startsWith(labelFilter.toLowerCase())
      )
      .map(({ id, color, name }) => (
        <a
          href="#"
          className="dropdown-item has-text-left"
          key={id}
          onMouseDown={() => updateLabel(id)}
        >
          <Label color={color}>
            {name}
          </Label>
        </a>
      ));

  /**
   *
   * Creates label options for dropdown menu
   */
  const getGroupOptions = () =>
    groups
      .filter(({ name }) =>
        name.toLowerCase().startsWith(groupFilter.toLowerCase())
      )
      .map(({ id, name }) => (
        <a
          href="#"
          className="dropdown-item has-text-left"
          key={id}
          onMouseDown={() => updateGroup(id)}
        >
          {name}
        </a>
      ));

  const handleLabelFilterChange = (e) => {
    const { value } = e.target;
    setLabelFilter(value);
  };

  const handleGroupFilterChange = (e) => {
    const { value } = e.target;
    setGroupFilter(value);
  };

  return (
    <Card>
      <div className="mb-3">
        <h3 className="has-text-weight-bold has-text-light">Label</h3>
        <div>
          <Label
            color={requesterLabel.color ? requesterLabel.color : 'grey'}
            deletable={requesterLabel.name ? true : false}
            onDelete={deleteLabel}
          >
            {requesterLabel.name ? requesterLabel.name : 'No Label'}
          </Label>
          <DropDown loading={isLabelLoading} className="is-pulled-right">
            <div className="field px-2">
              <p className="control has-icons-left">
                <input
                  className="input is-small"
                  type="text"
                  placeholder="Search labels"
                  value={labelFilter}
                  onChange={handleLabelFilterChange}
                />
                <span className="icon is-small is-left">
                  <i className="fas fa-search"></i>
                </span>
              </p>
            </div>
            {getLabelOptions()}
          </DropDown>
          <Button
            type="text"
            onClick={handleOverrideLabelChange}
            className="pb-4 p-2 is-pulled-right"
            tooltipText="Override Label"
          >
            <Icon size="small" color={overrideLabel ? 'primary' : 'grey'} icon="fa-edit" />
          </Button>
        </div>
      </div>
      <div className="my-3">
        <h3 className="has-text-weight-bold has-text-light">Group</h3>
        <div>
          <Label
            color="grey"
            deletable={requesterGroup.name ? true : false}
            onDelete={deleteGroup}
          >
            {requesterGroup.name ? requesterGroup.name : 'No Group'}
          </Label>
          <DropDown loading={isGroupLoading} className="is-pulled-right">
            <div className="field px-2">
              <p className="control has-icons-left">
                <input
                  className="input is-small"
                  type="text"
                  placeholder="Search groups"
                  value={groupFilter}
                  onChange={handleGroupFilterChange}
                />
                <span className="icon is-small is-left">
                  <i className="fas fa-search"></i>
                </span>
              </p>
            </div>
            {getGroupOptions()}
          </DropDown>
          <Button
            type="text"
            onClick={handleOverrideGroupChange}
            className="pb-4 p-2 is-pulled-right"
            tooltipText="Override Group"
          >
            <Icon size="small" color={overrideGroup ? 'primary' : 'grey'} icon="fa-edit" />
          </Button>
        </div>
      </div>
      <div className="mt-3">
        <h3 className="has-text-weight-bold has-text-light">Flags</h3>
        <div className="control is-flex is-justify-content-space-between">
          <Button
            loading={isBotLoading}
            type="text"
            onClick={handleIsBotChange}
            className="p-5"
          >
            <Icon
              size="large"
              color={requesterIsBot ? 'primary' : 'grey'}
              icon={!isBotLoading ? 'fa-robot' : ''}
            />
          </Button>
          <Button
            loading={isWhitelistLoading}
            type="text"
            className="p-5"
            onClick={handleIsWhitelistChange}
          >
            <Icon
              size="large"
              color={requesterIsWhitelist ? 'primary' : 'grey'}
              icon={!isWhitelistLoading ? 'fa-check-circle' : ''}
            />
          </Button>
          <Button
            loading={isBlacklistLoading}
            type="text"
            className="p-5"
            onClick={handleIsBlacklistChange}
          >
            <Icon
              size="large"
              color={requesterIsBlacklist ? 'primary' : 'grey'}
              icon={!isBlacklistLoading ? 'fa-ban' : ''}
            />
          </Button>
        </div>
      </div>
    </Card>
  );
};

ClassifierCard.propTypes = {
  groups: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      name: PropTypes.string,
      isBot: PropTypes.bool,
      isWhitelist: PropTypes.bool,
      isBlacklist: PropTypes.bool,
      labelId: PropTypes.string,
      labelName: PropTypes.string,
      labelColor: PropTypes.string,
    })
  ),
  labels: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      name: PropTypes.string,
      color: PropTypes.string,
    })
  ),
  requester: PropTypes.shape({
    id: PropTypes.string,
    isBot: PropTypes.bool,
    isWhitelist: PropTypes.bool,
    isBlacklist: PropTypes.bool,
    labelId: PropTypes.string,
    labelName: PropTypes.string,
    labelColor: PropTypes.string,
    groupId: PropTypes.string,
    groupName: PropTypes.string,
    overrideLabel: PropTypes.bool,
    overrideGroup: PropTypes.bool,
  }),
};

export default ClassifierCard;
