import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';

import { addNotification } from 'actions/globalActions';

import Button from 'components/common/Button';

const EditKeyForm = ({ name, onSubmitForm }) => {

  const dispatch = useDispatch();

  const [selectedName, setSelectedName] = useState(name);

  const handleNameChange = (e) => {
    const { value } = e.target;
    setSelectedName(value);
  };

  const validateName = (name) => {
    // only letters, numbers, whitespaces and _
    const nameRegEx = /^[A-Za-z0-9 _]+$/;
    if (!nameRegEx.test(name)) {
      return {
        type: 'warning',
        title: 'Invalid key name',
        description: 'Use only letters, numbers and spaces'
      };
    }
    return null;
  };

  const submitForm = () => {
    const validationErrors = [validateName(selectedName)].filter(e => e !== null);
    if (validationErrors.length === 0) {
      onSubmitForm({
        name: selectedName,
      });
      // clear input
      setSelectedName('');
    } else {
      dispatch(addNotification(validationErrors[0]));
    }
  };

  return (
    <div className="control">
      <div className="field">
        <label className="label">Name</label>
        <input
          className="input"
          name="name"
          type="text"
          value={selectedName}
          onChange={handleNameChange}
        />
        <p className="help">Only letters, numbers and whitespaces allowed</p>
      </div>

      <Button className="is-pulled-right" type="text" onClick={submitForm}>
        Save Changes
      </Button>
    </div>
  );

};

EditKeyForm.propTypes = {
  name: PropTypes.string.isRequired,
  onSubmitForm: PropTypes.func.isRequired,
};

export default EditKeyForm;
