import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

import './styles.scss';
import Spinner from '../Spinner';
import Cross from '../Cross';

const Drawer = ({
  isLoading = false,
  isError = false,
  isOpen = false,
  onClose,
  noCover = false,
  size = 'medium',
  children
}) => {

  const drawCover = () => (
    !noCover ? (
      <div
        className={cx('drawer-cover', {
          'is-open': isOpen,
        })}
        onClick={isLoading || isError ? null : onClose}
      />
    ) : ''
  );

  const drawError = () => (
    isError ? (
      <div className="drawer-loading-error is-flex is-justify-content-center is-align-items-center">
        <div className="has-text-centered px-5">
          <Cross className="content" />
          <p className="is-size-4">Something went wrong, please refresh the page!</p>
        </div>
      </div>
    ) : ''
  );

  const drawLoading = () => (
    isLoading ? (
      <div className="drawer-loading-error is-flex is-justify-content-center is-align-items-center">
        <div className="has-text-centered px-5">
          <Spinner className="content" />
          <p>Updating data</p>
        </div>
      </div>
    ) : ''
  );

  return (
    <>
      <div className={cx('drawer', `is-${size}`, {
        'is-open': isOpen,
      })}
      >
        <div className="drawer-header px-5 mt-2">
          <button className="delete is-pulled-right" onClick={onClose}></button>
        </div>
        <div className="card-content">
          {children}
        </div>
        {drawLoading()}
        {drawError()}
      </div>
      {drawCover()}
    </>
  );
};

Drawer.propTypes = {
  isLoading: PropTypes.bool,
  isError: PropTypes.bool,
  isOpen: PropTypes.bool,
  onClose: PropTypes.func,
  noCover: PropTypes.bool,
  size: PropTypes.oneOf(['small', 'medium', 'large']),
  children: PropTypes.any,
};

export default Drawer;
