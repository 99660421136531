import React from 'react';
import PropTypes from 'prop-types';
import LabelsTableRow from '../LabelsTableRow';

const LabelsTable = ({ labels = [], setLabels }) => {

  const drawLabelRows = () => (
    labels.map((label) => (
      <LabelsTableRow
        key={label.id}
        label={label}
        labels={labels}
        setLabels={setLabels}
      />
    ))
  );

  return (
    <table className="table is-fullwidth is-narrow">
      <thead>
        <tr>
          <th>Preview</th>
          <th>Name</th>
          <th>Priority</th>
          <th>Color</th>
          <th></th>
          <th></th>
        </tr>
      </thead>
      <tbody>
        {drawLabelRows()}
      </tbody>
    </table>
  );
};

LabelsTable.propTypes = {
  labels: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string,
    color: PropTypes.string
  })),
  setLabels: PropTypes.func
};

export default LabelsTable;
