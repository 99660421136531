import React, { useState, useEffect, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import api from 'services/api';

import Layout from 'components/Layout/Layout';
import Button from 'components/common/Button';
import Drawer from 'components/common/Drawer';
import GroupsTable from 'components/Groups/GroupsTable';
import NewGroupForm from 'components/Groups/NewGroupForm';
import { addNotification } from 'actions/globalActions';

const Groups = () => {
  const currentLogger = useSelector(state => state.global.currentLogger);
  const dispatch = useDispatch();

  const [isLoading, setIsLoading] = useState(true);
  const [isError, setIsError] = useState(false);
  const [groups, setGroups] = useState([]);
  const [labels, setLabels] = useState([]);
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [isDrawerLoading, setIsDrawerLoading] = useState(false);
  const [isDrawerError, setIsDrawerError] = useState(false);

  const createNewGroup = (data) => {
    setIsDrawerLoading(true);
    api.createGroup(currentLogger, data, res => {
      if (res.data.status === 200) {
        setGroups([
          ...groups,
          res.data.group
        ]);
        dispatch(addNotification({
          title: 'Group created successfully',
          type: 'success',
        }));
        setIsDrawerOpen(false);
      } else {
        setIsDrawerError(true);
      }
      setIsDrawerLoading(false);
    });
  };

  const fetchGroups = useCallback(() => {
    setIsLoading(true);
    setIsError(false);
    api.getGroups(currentLogger, res => {
      if (res.data.status === 200) {
        setGroups(res.data.groups);
        setLabels(res.data.labels);
      } else {
        setIsError(true);
      }
      setIsLoading(false);
    });
  }, [currentLogger]);

  /**
   * Calls fetchLogs onload.
   */
  useEffect(() => {
    fetchGroups();
  }, [fetchGroups]);


  return (
    <Layout title={'Groups'} loading={isLoading} error={isError} className="is-relative">
      <Drawer isError={isDrawerError} isLoading={isDrawerLoading} isOpen={isDrawerOpen} onClose={() => setIsDrawerOpen(false)}>
        <NewGroupForm onSubmitForm={createNewGroup} labels={labels} />
      </Drawer>
      <div className="columns">
        <div className="column">
          <h1 className="title mb-1">Groups</h1>
          <p className="has-text-weight-light">
            You can create Groups with custom labels and flags. After that,
            IPs can be added to at most one group for easier aggregation.
          </p>
        </div>
        <div className="column is-narrow">
          <Button onClick={() => setIsDrawerOpen(true)}>
            New Group
          </Button>
        </div>
      </div>
      <GroupsTable groups={groups} labels={labels} setGroups={setGroups} />
    </Layout>
  );
};

export default Groups;
