import React, { useEffect } from 'react';
import PropTypes from 'prop-types';

import Chart from 'chart.js/auto';

const BarChart = ({
  id = 'bar-chart',
  horizontal = false,
  height = 300,
  labels,
  data,
  backgroundColor
}) => {

  useEffect(() => {
    const barChart = new Chart(id, {
      type: 'bar',
      data: {
        labels,
        datasets: [
          {
            data,
            barPercentage: 1,
            backgroundColor,
            borderRadius: 4,
          },
        ],
      },
      options: {
        indexAxis: horizontal ? 'y' : 'x',
        responsive: true,
        maintainAspectRatio: false,
        plugins: {
          legend: {
            display: false,
          },
        },
        scales: {
          x: {
            beginAtZero: false,
            grid: {
              display: true,
              drawBorder: true,
              drawOnChartArea: true,
            },
          },
          y: {
            ticks: {
              stepSize: 1,
            },
            beginAtZero: true,
            grid: {
              display: false,
              drawBorder: true,
            },
          },
        },
        // tooltips: {
        //   callbacks: {
        //     label: function (tooltipItem, data) {
        //       const dataset = data.datasets[tooltipItem.datasetIndex];
        //       const total = dataset.data.reduce(function (
        //         previousValue,
        //         currVal
        //       ) {
        //         return parseFloat(previousValue) + parseFloat(currVal);
        //       });

        //       const currentValue = dataset.data[tooltipItem.index];
        //       const percentage = (currentValue / total) * 100;

        //       return (
        //         ' ' +
        //         currentValue +
        //         ' requests (' +
        //         percentage.toFixed(2) +
        //         '%)'
        //       );
        //     },
        //   },
        // },
      },
    });

    // when component unmounts
    return () => {
      barChart.destroy();
    };
  }, [backgroundColor, data, horizontal, id, labels]);

  return (
    <div className="chart-container">
      <canvas id={id} height={height} />
    </div>
  );
};

BarChart.propTypes = {
  id: PropTypes.string,
  horizontal: PropTypes.bool,
  data: PropTypes.arrayOf(PropTypes.any),
  labels: PropTypes.arrayOf(PropTypes.any),
  height: PropTypes.number,
  backgroundColor: PropTypes.any,
};

export default BarChart;
