import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

import './styles.scss';

const Divider = ({ vertical = false }) => (
  <div className={cx('divider', {
    'horizontal-divider': !vertical,
    'vertical-divider': vertical,
  })}
  />
);

Divider.propTypes = {
  vertical: PropTypes.bool,
};

export default Divider;
