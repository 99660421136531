import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';

import routes from 'routes';

import Paginator from 'components/common/Paginator';
import Label from 'components/common/Label';
import DropDown from 'components/common/Dropdown';
import Proptypes from 'prop-types';
import api from 'services/api';
import EditableLabel from 'components/common/EditableLabel';

import './style.scss';

// To draw the method label specify the position
const Table = ({
  headerAttributes,
  drawMethodPosition,
  drawIpAddressPosition,
  drawURIPosition,
  drawLabelPosition,
  drawAddToGroupPosition,
  drawAddRequesterToGroupPosition,
  data,
  curPage,
  nPages,
  callBackUrl,
  setLoading,
}) => {
  const [stateData, setStateData] = useState(data);

  useEffect(() => {
    setStateData(data);
  }, [stateData, data]);

  const drawHeader = (items) => {
    return items.map((item) => {
      let className = '';

      switch (item) {
        case 'URI':
          className = 'table-uri-header-field';
          break;
        case 'IP Address':
          className = 'table-ip-header-field';
          break;
        case 'Method':
          className = 'table-method-header-field';
          break;
        case ' ':
          className = 'table-label-header-field';
          break;
        default:
          break;
      }

      return (
        <th className={className + ' has-text-centered'} key={item}>
          {item}
        </th>
      );
    });
  };

  const drawMethodLabel = (method) => {
    switch (method) {
      case 'GET':
        return (
          <Label color="#26AA6C" textColor="white">
            {method}
          </Label>
        );
      case 'POST':
        return (
          <Label color="#FFC857" textColor="black">
            {method}
          </Label>
        );
      case 'PUT':
        return (
          <Label color="#00B4D8" textColor="white">
            {method}
          </Label>
        );
      case 'DELETE':
        return (
          <Label color="#DF2B2B" textColor="white">
            {method}
          </Label>
        );
      default:
        return <Label>{method}</Label>;
    }
  };

  const drawAddToGroup = (groupData) => {
    const pairs = [];
    for (const group of groupData.groups) {
      pairs.push({
        name: group.name,
        value: group.id,
        color: group.colorLabel,
      });
    }
    const addToBotsList = (pair, setIsVisible) => {
      api.addToBots(
        groupData.logger,
        { id: groupData.requestId, groupId: pair.value },
        (res) => {
          if (res.data.status === 200) {
            setIsVisible(false);
          } else if (res.data.status === 409) {
            // Conflict with trying to add to a group a user who already has one
            setIsVisible(false);
          }
        }
      );
    };
    return (
      <DropDown
        visible={groupData.curGroup === 'None'}
        nameValuePairs={pairs}
        callBackOnSelect={addToBotsList}
      />
    );
  };
  const drawAddRequesterToGroup = (groupData, curGroup) => {
    const pairs = [];
    for (const group of groupData.groups) {
      if (group.name !== curGroup.name) {
        pairs.push({
          name: group.name,
          value: group.id,
          color: group.colorLabel,
        });
      }
    }
    const addToGroup = (pair) => {
      api.addToGroup(
        groupData.logger,
        { id: groupData.requesterId, groupId: pair.value },
        (res) => {
          if (res.data.status === 200) {
            const newState = [...stateData];
            newState[groupData.index][drawLabelPosition] = {
              name: pair.name,
              color: pair.color,
            };
            setStateData(newState);
          }
        }
      );
    };
    return (
      <DropDown
        nameValuePairs={pairs}
        callBackOnSelect={addToGroup}
        visible={true}
      />
    );
  };
  const drawData = (items) => {
    if (!items) {
      return;
    }
    return items.map((item, rowIndex) => (
      <tr key={rowIndex}>
        {item.map((column, colIndex) => {
          if (colIndex === 0) {
            // first is id
            return;
          }
          if (colIndex === drawMethodPosition) {
            return <td key={colIndex}>{drawMethodLabel(column)}</td>;
          }
          if (colIndex === drawURIPosition) {
            return (
              <td
                title={column}
                className="table-uri-data-field"
                key={colIndex}
              >
                {column}
              </td>
            );
          }
          if (colIndex === drawIpAddressPosition) {
            const { ipAddress, requesterId, loggerId } = column;
            const link = routes.requestersRequests.ref(loggerId, requesterId);
            return (
              <td
                title={ipAddress}
                className="table-ip-data-field"
                key={colIndex}
              >
                <Link to={link}>{ipAddress}</Link>
              </td>
            );
          }
          if (colIndex === drawLabelPosition) {
            return (
              <td key={colIndex}>
                <EditableLabel
                  labelName={column.name}
                  labelColor={column.color}
                  requesterId={column.requesterId}
                  setLoading={setLoading}
                  labels={column.labels}
                />
              </td>
            );
          }
          if (colIndex === drawAddToGroupPosition) {
            return <td key={colIndex}>{drawAddToGroup(column)}</td>;
          }
          if (colIndex === drawAddRequesterToGroupPosition) {
            return (
              <td key={colIndex}>{drawAddRequesterToGroup(column, item[6])}</td>
            );
          }
          return <td key={colIndex}>{column}</td>;
        })}
      </tr>
    ));
  };

  return (
    <div className="card table-container">
      <table className="table my-table has-text-centered is-fullwidth">
        <thead className="table-header">
          <tr>{drawHeader(headerAttributes)}</tr>
        </thead>
        <tfoot>
          <tr>
            <td colSpan={headerAttributes.length}>
              <Paginator
                currentPage={curPage}
                pagesNum={nPages}
                callBackUrl={callBackUrl}
                setLoading={setLoading}
              />
            </td>
          </tr>
        </tfoot>
        <tbody className="table-body">{drawData(stateData)}</tbody>
      </table>
    </div>
  );
};

Table.propTypes = {
  headerAttributes: Proptypes.arrayOf(Proptypes.string),
  drawMethodPosition: Proptypes.number,
  drawURIPosition: Proptypes.number,
  drawIpAddressPosition: Proptypes.number,
  drawLabelPosition: Proptypes.number,
  drawAddToGroupPosition: Proptypes.number,
  drawAddRequesterToGroupPosition: Proptypes.number,
  data: Proptypes.arrayOf(Proptypes.array),
  curPage: Proptypes.number,
  nPages: Proptypes.number,
  callBackUrl: Proptypes.func,
  setLoading: Proptypes.func,
};

export default Table;
