import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

import routes from 'routes';
import { truncateIntegerNumber } from 'services/valueFormatter';

import Card from 'components/common/Card';
import Icon from 'components/common/Icon';
import Button from 'components/common/Button';

const IPAddressesCard = ({ ipAddresses = [] }) => {

  const currentLogger = useSelector((state) => state.global.currentLogger);

  const [page, setPage] = useState(0); // page 0 or 1
  const perPage = 10;

  const drawIPAddressesRows = () => (
    ipAddresses.map(({ requesterId, ipAddress, count }, index) => {
      if (index >= page * perPage && index < (page + 1) * perPage) {
        const link = routes.requestersRequests.ref(currentLogger, requesterId);
        return (
          <tr
            key={ipAddress}
          >
            <td>
              <p className="is-size-7 has-tooltip-top" data-tooltip={ipAddress}>
                <Link
                  className="has-text-primary"
                  to={link}
                >
                  {ipAddress}
                </Link>
              </p>
              <p className="is-size-7">
                <span className="has-text-weight-bold">
                  {truncateIntegerNumber(count)}
                </span>
                &nbsp;requests
              </p>
            </td>
          </tr>
        );
      }
    })
  );

  const drawPagination = () => {
    if (ipAddresses.length !== 0) {
      return (
        <div className="is-flex is-justify-content-center">
          <Button
            type="text"
            size="small"
            className="mr-2"
            onClick={() => setPage(0)}
          >
            <Icon
              color={page === 0 ? 'grey' : 'primary'}
              icon="fa-chevron-left"
            />
          </Button>
          <Button
            type="text"
            size="small"
            className="ml-2"
            onClick={() => setPage(1)}
          >
            <Icon
              color={page === 1 ? 'grey' : 'primary'}
              icon="fa-chevron-right"
            />
          </Button>
        </div>
      );
    }
  };

  return (
    <Card>
      <h3 className="mb-2">
        <Link className="has-text-weight-bold has-text-light" to={routes.topDailyRequesters.ref(currentLogger)}>
          Top 20 IP Addresses
        </Link>
      </h3>
      <div style={{ height: 500 }}>
        <table className="table is-fullwidth is-narrow">
          <tbody>
            {drawIPAddressesRows()}
          </tbody>
        </table>
        {drawPagination()}
      </div>
    </Card>
  );
};

IPAddressesCard.propTypes = {
  ipAddresses: PropTypes.arrayOf(
    PropTypes.shape({
      requesterId: PropTypes.string,
      ipAddress: PropTypes.string,
      count: PropTypes.any,
    })
  )
};

export default IPAddressesCard;
