import React from 'react';
import PropTypes from 'prop-types';

import Card from 'components/common/Card';
import BarChart from 'components/common/BarChart';

const RequestsChart = ({ chartData = [] }) => {
  const backgroundColor = '#b0413e';
  // sort current hour should be the last element
  const currentHour = new Date().getHours();
  const sortedChartData = [
    ...chartData.filter(elem => elem.hour > currentHour),
    ...chartData.filter(elem => elem.hour <= currentHour)
  ];
  const data = sortedChartData.map((elem) => elem.count);
  const labels = sortedChartData.map((elem) => elem.hour);

  return (
    <Card>
      <h3 className="has-text-weight-bold has-text-light">
        Requests from the last 24 hours
      </h3>
      <BarChart
        id={'requester-bar-chart'}
        data={data}
        labels={labels}
        backgroundColor={backgroundColor}
        height={380}
      />
    </Card>
  );
};

RequestsChart.propTypes = {
  chartData: PropTypes.arrayOf(
    PropTypes.shape({
      count: PropTypes.number,
      hour: PropTypes.number,
    })
  ),
};

export default RequestsChart;
