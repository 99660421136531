import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Button from 'components/common/Button';
import { useDispatch } from 'react-redux';

import ColorPicker from '../ColorPicker';
import { addNotification } from 'actions/globalActions';

const NewLabelForm = ({ onSubmitForm, priorities }) => {
  const dispatch = useDispatch();

  const [selectedName, setSelectedName] = useState('');
  const [selectedColor, setSelectedColor] = useState('');
  const [selectedPriority, setSelectedPriority] = useState(0);

  const handleNameChange = (e) => {
    const { value } = e.target;
    setSelectedName(value);
  };

  const handleColorChange = (e) => {
    const { value } = e.target;
    setSelectedColor(value);
  };

  const handlePriorityChange = (e) => {
    const { value } = e.target;
    setSelectedPriority(value);
  };

  const validateColor = (color) => {
    const hexColorRegEx = /^#[a-fA-F0-9]{6}/;
    if (!hexColorRegEx.test(color)) {
      return {
        type: 'warning',
        title: 'Invalid label color',
        description: 'Use only hex colors',
      };
    }
    return null;
  };

  const validatePriority = (number) => {
    const numRegEX = /^\d+$/;
    if (!numRegEX.test(number)) {
      return {
        type: 'warning',
        title: 'Invalid priority value',
        description: 'Use only numbers',
      };
    } else if (priorities.includes(parseInt(number))) {
      return {
        type: 'warning',
        title: 'Invalid priority value',
        description: 'Must be different from the other priority values',
      };
    }
    return null;
  };

  const validateName = (name) => {
    // only letters, numbers and whitespaces
    const nameRegEx = /^[A-Za-z0-9 ]+$/;
    if (!nameRegEx.test(name)) {
      return {
        type: 'warning',
        title: 'Invalid label name',
        description: 'Use only letters, numbers and spaces',
      };
    }
    return null;
  };

  const submitForm = () => {
    const validationErrors = [
      validateName(selectedName),
      validateColor(selectedColor),
      validatePriority(selectedPriority),
    ].filter((e) => e !== null);
    if (validationErrors.length === 0) {
      onSubmitForm({
        name: selectedName,
        color: selectedColor,
        priority: parseInt(selectedPriority),
      });
      // clear input
      setSelectedName('');
      setSelectedColor('');
    } else {
      dispatch(addNotification(validationErrors[0]));
    }
  };

  return (
    <>
      <div className="field">
        <label className="label">Name</label>
        <div className="control">
          <input
            className="input"
            name="name"
            type="text"
            value={selectedName}
            onChange={handleNameChange}
          />
        </div>
        <p className="help">Only letters, numbers and whitespaces allowed</p>
      </div>

      <div className="field">
        <label className="label">Color</label>
        <div className="control has-icons-left">
          <input
            className="input"
            name="name"
            type="text"
            value={selectedColor}
            onChange={handleColorChange}
          />
          <span
            className="icon is-small is-left"
            style={{
              color: selectedColor,
            }}
          >
            <i className="fas fa-circle"></i>
          </span>
        </div>
        <p className="help">Only hexadecimal colors allowed</p>
      </div>

      <ColorPicker color={selectedColor} setColor={setSelectedColor} />

      <div className="field">
        <label className="label">Priority</label>
        <input
          className="input"
          name="name"
          type="number"
          value={selectedPriority}
          onChange={handlePriorityChange}
        />
      </div>

      <Button className="is-pulled-right" type="text" onClick={submitForm}>
        Create Label
      </Button>
    </>
  );
};

NewLabelForm.propTypes = {
  onSubmitForm: PropTypes.func,
  priorities: PropTypes.arrayOf(PropTypes.number),
};

export default NewLabelForm;
